import { ReactNode } from 'react';

export function NumberBubble({
    count,
    children,
}: {
    count: number;
    children?: ReactNode;
}) {
    return (
        <div className="relative grid">
            {children}
            <div className="atl-comment-bubble">
                {count === 1 && (
                    <div className="atl-comment__button-one-comment"></div>
                )}
                {count > 1 && count < 10 && (
                    <div className="atl-comment__button-count atl-comment-font-12">
                        {count < 10 ? count : '9+'}
                    </div>
                )}
                {count > 9 && (
                    <div className="atl-comment__button-count atl-comment-font-12 atl-px-2">
                        {count < 10 ? count : '9+'}
                    </div>
                )}
            </div>
        </div>
    );
}
