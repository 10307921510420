import { UseSearchCategoryReturn } from '../types';

import { isDefined } from '../../../utils/isDefined';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { travelExpenseCategory, TravelExpenseDTO } from './types';

export function TravelExpenseResult(
    props: UseSearchCategoryReturn<TravelExpenseDTO>,
) {
    const translation = useResultTitleTranslation(
        'text_travel_reports_or_employee_expenses_short',
    );

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={travelExpenseCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext(`/execute/travelsAndExpenses`)}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: TravelExpenseDTO) => (
                    <TravelExpenseResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={travelExpenseCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function TravelExpenseResultItem({ data }: { data: TravelExpenseDTO }) {
    const url = getTravelExpenseURL(data);

    if (!isDefined(url)) {
        return null;
    }

    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-travelexpense-icon">
                {data.travelDetails ? 'flight_takeoff' : 'local_mall'}
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={travelExpenseCategory}>
                <SearchResultItemTitle>
                    {data.travelDetails?.destination}
                </SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_name'}>
                    {`${data.employee?.firstName ?? ''} ${
                        data.employee?.lastName ?? ''
                    }`}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_number'}>
                    {data.number}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_departure_date'}>
                    {data.travelDetails?.departureDate}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_purpose'}>
                    {data.travelDetails?.purpose}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_amount'}>
                    {data.amount}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}

function getTravelExpenseURL(data: TravelExpenseDTO) {
    if (data.id === undefined) {
        return undefined;
    }

    if (data.travelDetails) {
        return getUrlWithContext('/execute/travelreportMenu', {
            travelReportId: data.id.toString(),
        });
    }

    return getUrlWithContext('/execute/expense', {
        expenseId: data.id.toString(),
    });
}
