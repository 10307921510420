import { useMemo, useState } from 'react';
import {
    RecentThreadsProps,
    RecentThreadsView,
    useRecentThreads,
} from './RecentThreadsView';
import { ConnectedThreadView } from './ConnectedThreadView';
import { CreateNewThread } from './CreateNewThread';
import { ThreadDTO } from './Chat.types';

export function Chat({ onClose }: { onClose: () => void }) {
    const recentThreadProps = useRecentThreads();

    return (
        <ChatView onClose={onClose} recentThreadsProps={recentThreadProps} />
    );
}

export function ChatView({
    onClose,
    recentThreadsProps,
}: {
    onClose: () => void;
    recentThreadsProps: RecentThreadsProps;
}) {
    const [currentView, setCurrentView] = useState<
        'threads' | 'newThread' | number
    >('threads');

    const {
        fetchThreadsResponse: { threads, mutate },
    } = recentThreadsProps;

    const currentThread = useMemo(
        () =>
            typeof currentView !== 'number'
                ? undefined
                : threads.find((thread) => thread.id === currentView),
        [currentView, threads],
    );

    const handleCreateThread = async (thread: ThreadDTO) => {
        const options = {
            revalidate: false,
        };

        await mutate((currentData) => {
            if (currentData === undefined) {
                return undefined;
            }

            return {
                ...currentData,
                values: [...currentData.values, thread],
            };
        }, options);

        setCurrentView(thread.id);
    };

    if (typeof currentView === 'number' && currentThread) {
        return (
            <ConnectedThreadView
                onClose={onClose}
                thread={currentThread}
                onBack={() => setCurrentView('threads')}
            />
        );
    }

    if (currentView === 'newThread') {
        return (
            <CreateNewThread
                onClose={onClose}
                onBack={() => setCurrentView('threads')}
                onCreateThread={handleCreateThread}
            />
        );
    }

    return (
        <RecentThreadsView
            onClose={onClose}
            onClickThread={setCurrentView}
            onClickNewThread={() => setCurrentView('newThread')}
            {...recentThreadsProps}
        />
    );
}
