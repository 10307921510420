import useSWRMutation from 'swr/mutation';
import {
    createAPIRequest,
    createAPIResponse,
    ResponseWrapper,
} from '@tlx/astro-shared';
import { MESSAGE_FIELDS, MessageDTO } from '../../Chat.types';

const URL = `/v2/internal/thread/message?fields=${MESSAGE_FIELDS}`;

type PostMessageDTO = {
    threadId: number;
    content: string;
};

export function usePostMessageApi() {
    const { trigger, data, error, isMutating } = useSWRMutation(
        URL,
        postMessage,
    );

    return {
        postMessage: trigger,
        data,
        error,
        isMutating,
    };
}

async function postMessage(url: string, { arg }: { arg: PostMessageDTO }) {
    const request = createAPIRequest(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(arg),
    });

    const response = await fetch(request);
    return await createAPIResponse<ResponseWrapper<MessageDTO>>(
        request,
        response,
    );
}
