import useSWRMutation from 'swr/mutation';
import {
    createAPIRequest,
    createAPIResponse,
    ResponseWrapper,
} from '@tlx/astro-shared';
import { THREAD_FIELDS, ThreadDTO } from '../../Chat.types';

const URL = `/v2/internal/thread?fields=${THREAD_FIELDS}`;

type ThreadPostDTO = {
    text: string;
    url?: string;
    participants: number[];
};

export function usePostCreateThreadApi() {
    const { trigger, data, error, isMutating } = useSWRMutation(
        URL,
        postCreateThread,
    );

    return {
        createNewThread: trigger,
        data,
        error,
        isMutating,
    };
}

async function postCreateThread(
    url: string,
    { arg }: { arg: { thread: ThreadPostDTO } },
) {
    const request = createAPIRequest(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(arg.thread),
    });

    const response = await fetch(request);
    return await createAPIResponse<ResponseWrapper<ThreadDTO>>(
        request,
        response,
    );
}
