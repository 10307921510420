import { isDefined } from './isDefined';

export function getLocalStorageItem<T>(key: string, initialValue: T): T {
    try {
        const item = window.localStorage.getItem(key);

        return isDefined(item) ? JSON.parse(item) : initialValue;
    } catch (error) {
        console.warn(`Could not get localStorage key "${key}"`, error);

        return initialValue;
    }
}

export function setLocalStorageItem<T>(key: string, value: T): void {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.warn(`Could not set localStorage key "${key}"`, error);
    }
}

export function removeLocalStorageItem(key: string): void {
    try {
        window.localStorage.removeItem(key);
    } catch (error) {
        console.warn(`Could not remove localStorage key "${key}"`, error);
    }
}
