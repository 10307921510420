import { ChatMenu } from './ChatMenu';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export function ChatElement() {
    const isProduction = window.location.hostname.includes('tripletex');

    if (isProduction) {
        return <div className="production h-[34px] w-0"></div>;
    }

    return (
        <ErrorBoundary>
            <ChatMenu />
        </ErrorBoundary>
    );
}
