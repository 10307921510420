import { InlineMarkdown } from '@tlx/astro-shared';
import { Alert, AlertCloseButton, AlertContent } from '@tlx/atlas';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export function UnsupportedBrowserWarning({
    isSupported = isBrowserSupported(),
}: {
    isSupported?: boolean;
}) {
    const { formatMessage } = useIntl();
    const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => setIsOpen(false);

    if (!isOpen || isSupported) {
        return null;
    }

    const browserWarningMessage = formatMessage({
        id: 'text_unsupported_browser',
        defaultMessage: 'Your browser is not supported.',
    });

    return (
        <Alert variant="warning" className="atl-mx-24 atl-my-16">
            <AlertContent>
                <InlineMarkdown>{browserWarningMessage}</InlineMarkdown>
            </AlertContent>
            <AlertCloseButton
                data-testid="browser-warning-close-button"
                onClick={handleClose}
            />
        </Alert>
    );
}

/**
 * We use a "cutting the mustard" approach to browser support, where supported
 * browsers need to support one more modern features.
 *
 * Our current "cutting the mustard" check is for CSS subgrid support, mainly
 * due to tables using subgrid.
 *
 * @see https://responsivenews.co.uk/post/18948466399/cutting-the-mustard
 * @see https://caniuse.com/css-subgrid
 */
function isBrowserSupported(): boolean {
    return (
        CSS.supports('grid-template-columns', 'subgrid') &&
        typeof Array.prototype.toSorted === 'function' &&
        typeof Promise.withResolvers === 'function'
    );
}
