import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import { SelfDescribingJson } from '@snowplow/tracker-core';
import { isDefined } from '../../utils/isDefined';

/**
 * Describes which rows a user has selected in a table.
 */
export interface TableSelectionContext extends SelfDescribingJson {
    schema: 'iglu:no.tripletex/context_table_selection/jsonschema/1-0-1';
    data: {
        /**
         * An id identifying the table on the page. The tableid is not necessarily unique across Tripletex.
         */
        tableId?: string;

        /**
         * Ids of the selected elements in the table. Can be empty if no elements are selected.
         */
        selectedElementIds?: number[];

        /**
         * Ids of the available elements in the table page. Can be empty while still having data in amountAvailableElementIds.
         */
        availableElementIds?: number[];

        /**
         * The amount of available element ids on the page. When availableElementIds is set, this is equivalent to its size.
         */
        amountAvailableElementIds?: number;

        /**
         * If all elements on the page are selected this property is true.
         */
        allElementsSelected?: boolean;
    };
}

export function TripletexTableSelectionPlugin(): BrowserPlugin {
    return {
        contexts: getTableSelectionContexts,
    };
}

export function getTableSelectionContexts(): TableSelectionContext[] {
    const contexts: TableSelectionContext[] = [];

    for (const table of document.querySelectorAll('table')) {
        const context = getTableSelectionContext(table);

        if (context) {
            contexts.push(context);
        }
    }

    return contexts;
}

function getTableSelectionContext(
    table: HTMLTableElement,
): TableSelectionContext | null {
    const availableElementIds = getAvailableElementIds(table);

    if (availableElementIds.length === 0) {
        return null;
    }

    const selectedElementIds = getSelectedElementIds(table);

    return {
        schema: 'iglu:no.tripletex/context_table_selection/jsonschema/1-0-1',
        data: {
            tableId: getTableId(table),
            selectedElementIds,
            amountAvailableElementIds: availableElementIds.length,
            allElementsSelected:
                selectedElementIds.length === availableElementIds.length,
        },
    };
}

export function getAvailableElementIds(table: HTMLTableElement): number[] {
    const ids: number[] = [];

    // offer/order tables have hidden input as direct children of tbody 🤷‍♂️
    for (const hiddenInput of table.querySelectorAll<HTMLInputElement>(
        'td.select input[type="hidden"][name$="].id"],tbody > input[type="hidden"][name$="].id"]',
    )) {
        ids.push(Number(hiddenInput.value));
    }

    return ids;
}

export function getSelectedElementIds(table: HTMLTableElement): number[] {
    const ids: number[] = [];

    for (const checkbox of table.querySelectorAll<HTMLInputElement>(
        'td.select input[type="checkbox"]',
    )) {
        if (checkbox?.checked) {
            const hiddenInputName = checkbox.name.replace('].selected', '].id');
            const hiddenInput = table.querySelector<HTMLInputElement>(
                `input[type="hidden"][name="${hiddenInputName}"]`,
            );

            if (hiddenInput) {
                ids.push(Number(hiddenInput.value));
            }
        }
    }

    return ids;
}

function getTableId(table: HTMLTableElement): string {
    if (isDefined(table.dataset.trackingid)) return table.dataset.trackingid;
    if (isDefined(table.dataset.testid)) return table.dataset.testid;
    return table.id;
}
