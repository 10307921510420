import {
    HTTPError,
    createAPIRequest,
    createAPIResponse,
} from '@tlx/astro-shared';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isDefined } from '../../utils/isDefined';

export const SUMMARY_FETCH_SIZE = 3;
export const DRILL_DOWN_FETCH_SIZE = 10;

// 1 minutes in milliseconds
const MAX_CACHE_AGE_MS = 1 * 60 * 1000;

export function useApiSearch<T>(
    endpoint: string,
    query: string,
    searchParams?: URLSearchParams,
    // can be overriden for testing
    fetch = window.fetch,
) {
    const [response, setResponse] = useState<{
        loading: boolean;
        hasMore: boolean;
        initialData: T[] | undefined;
        subsequentData: T[] | undefined;
    }>({
        loading: false,
        hasMore: false,
        initialData: undefined,
        subsequentData: undefined,
    });
    // keeps a reference to the current search,
    // so we can cancel it if the query changes
    const currentSearch = useRef<{ cancel: () => void }>();

    // Keeps track of our "from" offset
    const from = useRef(0);

    // Simple in-memory cache with a TTL
    // Key: string (e.g., query + from + count + searchParams)
    // Value: { values: T[], hasMore: boolean, timestamp: number }
    const cacheRef = useRef<
        Map<string, { values: T[]; hasMore: boolean; timestamp: number }>
    >(new Map());

    const getCacheKey = useCallback(
        (
            endpoint: string,
            userQuery: string,
            offset: number,
            limit: number,
            extraParams?: URLSearchParams,
        ) => {
            const paramsString = extraParams?.toString() || '';
            return `${endpoint}-${userQuery}__from=${offset}__count=${limit}__${paramsString}`;
        },
        [],
    );

    const doSearch = useCallback(
        (
            apiEndpoint: string,
            userQuery: string,
            offset: number,
            count: number,
            extraParams?: URLSearchParams,
        ) => {
            // Cancel any existing search in-flight
            currentSearch.current?.cancel();

            if (userQuery.trim() === '') {
                return;
            }

            let cancelled = false;

            const cacheKey = getCacheKey(
                endpoint,
                userQuery,
                offset,
                count,
                extraParams,
            );
            const cachedEntry = cacheRef.current.get(cacheKey);

            if (cachedEntry) {
                const isExpired =
                    Date.now() - cachedEntry.timestamp > MAX_CACHE_AGE_MS;
                if (!isExpired) {
                    setResponse((prev) => {
                        // If offset > 0, we are loading more results
                        if (offset > 0) {
                            const newSubsequentData = [
                                ...(prev.subsequentData || []),
                                ...cachedEntry.values,
                            ];
                            return {
                                loading: false,
                                hasMore: cachedEntry.hasMore,
                                initialData: prev.initialData,
                                subsequentData: newSubsequentData,
                            };
                        } else {
                            return {
                                loading: false,
                                hasMore: cachedEntry.hasMore,
                                initialData: cachedEntry.values,
                                subsequentData: undefined,
                            };
                        }
                    });

                    return;
                } else {
                    // If it's expired, we remove it from the cache
                    cacheRef.current.delete(cacheKey);
                }
            }

            // Not cached or entry was stale => do a real network request
            async function fetchData() {
                setResponse((prev) => ({
                    initialData: offset === 0 ? undefined : prev.initialData,
                    subsequentData:
                        offset > 0 ? prev.subsequentData : undefined,
                    loading: true,
                    hasMore: false,
                }));

                try {
                    // append pagination params to the given request parameters
                    const params = new URLSearchParams(extraParams);
                    params.set('count', count.toString());
                    params.set('from', offset.toString());
                    params.set('query', userQuery);

                    const urlWithParams = `${apiEndpoint}?${params}`;
                    const request = createAPIRequest(urlWithParams);
                    const fetchResponse = await fetch(request);
                    const responseBody = await createAPIResponse<{
                        values: T[];
                        from: number;
                        fullResultSize: number;
                        count: number;
                    }>(request, fetchResponse);

                    if (!cancelled) {
                        const isALoadMoreCall = offset > 0;
                        const hasMore =
                            responseBody.count > 0 &&
                            responseBody.from + responseBody.count <
                                responseBody.fullResultSize;

                        // Update state with fetched data
                        setResponse((currentResponse) => {
                            let subsequentData = currentResponse.subsequentData;
                            let initialData = currentResponse.initialData;

                            if (isALoadMoreCall) {
                                subsequentData = [
                                    ...(subsequentData || []),
                                    ...responseBody.values,
                                ];
                            } else {
                                initialData = responseBody.values;
                            }

                            return {
                                loading: false,
                                initialData,
                                subsequentData,
                                hasMore,
                            };
                        });

                        // Store data in cache with a timestamp
                        cacheRef.current.set(cacheKey, {
                            values: responseBody.values,
                            hasMore: hasMore,
                            timestamp: Date.now(),
                        });
                    }
                } catch (error) {
                    // On error, reset
                    setResponse({
                        loading: false,
                        initialData: undefined,
                        subsequentData: undefined,
                        hasMore: false,
                    });

                    // We don't care about 401 errors, since they are expected when a user is unauthorized
                    if (
                        isAuthorizationError(error) ||
                        isForbiddenError(error)
                    ) {
                        return;
                    }

                    // Log other execptions to Sentry
                    window.Sentry?.captureException(error);
                }
            }

            // trigger the fetching
            fetchData();

            return {
                cancel: () => {
                    cancelled = true;
                },
            };
        },
        [fetch, getCacheKey],
    );

    // Manually load more data
    const loadMore = () => {
        if (response.loading) {
            return;
        }

        currentSearch.current = doSearch(
            endpoint,
            query,
            from.current === 0 ? SUMMARY_FETCH_SIZE : from.current,
            from.current === 0
                ? DRILL_DOWN_FETCH_SIZE - SUMMARY_FETCH_SIZE
                : DRILL_DOWN_FETCH_SIZE,
            searchParams,
        );

        from.current += DRILL_DOWN_FETCH_SIZE;
    };

    // Go back to the initial page
    const goBack = () => {
        if (from.current === 0) {
            return;
        }
        from.current = 0;
        currentSearch.current?.cancel();

        setResponse((res) => ({
            hasMore: true,
            loading: false,
            initialData: res.initialData,
            subsequentData: undefined,
        }));
    };

    // Trigger a new search when query changes
    useEffect(() => {
        if (query.length < 3) {
            return;
        }
        from.current = 0;
        currentSearch.current = doSearch(
            endpoint,
            query,
            from.current,
            SUMMARY_FETCH_SIZE,
            searchParams,
        );
        return () => {
            // as a cleanup cancel the running search
            currentSearch.current?.cancel();
        };
    }, [query, searchParams, endpoint, doSearch]);

    let data: T[] | undefined;
    if (isDefined(response.initialData)) {
        data = [];
        data.push(...response.initialData);
        if (isDefined(response.subsequentData)) {
            data.push(...response.subsequentData);
        }
    }

    return {
        loading: response.loading,
        hasMore: response.hasMore,
        data,
        loadMore,
        goBack,
    };
}

function isAuthorizationError(error: unknown): boolean {
    return error instanceof HTTPError && error.response.status === 401;
}

function isForbiddenError(error: unknown): boolean {
    return error instanceof HTTPError && error.response.status === 403;
}
