import { isDefined } from '../../../utils/isDefined';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItemTitle,
    SearchResultItems,
    SearchResultSkeleton,
    SearchResultTitle,
} from '../SearchContainer';
import { UseSearchCategoryReturn } from '../types';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { MenuItemDTO, menuCategory } from './types';

export function MenuResult(props: UseSearchCategoryReturn<MenuItemDTO>) {
    const translation = useResultTitleTranslation('text_menu_items');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={menuCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: MenuItemDTO) => (
                    <MenuResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={menuCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function MenuResultItem({ data }: { data: MenuItemDTO }) {
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-menu-icon">
                menu
            </SearchResultItemIcon>
            <SearchResultItemLink href={data.url} category={menuCategory}>
                <SearchResultItemTitle>{data.title}</SearchResultItemTitle>
                {isDefined(data.category) && (
                    <span className="atl-text-sm">{data.category}</span>
                )}
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
