import { AvatarById, InlineMarkdown, ListResponse } from '@tlx/astro-shared';
import {
    ArrowBackIcon,
    Button,
    CloseIcon,
    InputProps,
    SendIcon,
    StackedAvatarContainer,
    TextField,
} from '@tlx/atlas';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { KeyedMutator } from 'swr/_internal';
import { isDefined } from '../../../utils/isDefined';
import { EmployeeDTO, MessageDTO, ThreadDTO } from './Chat.types';
import {
    ChatLayoutContent,
    ChatLayoutFooter,
    ChatLayoutHeader,
} from './ChatLayout';
import { RelativeTimeStamp } from './RelativeTimeStamp/RelativeTimeStamp';
import { usePostMessageApi } from './api/actions/postMessageApi';
import { putReadPointer } from './api/actions/putReadPointer';
import { useLoggedInEmployee } from './api/fetchers/useLoggedInEmployee';
import { useIntersectionObserver } from './hooks/useIntersectionObserver';

type ThreadMessageProps = {
    message: MessageDTO;
};

const ThreadMessage = forwardRef<HTMLDivElement, ThreadMessageProps>(
    ({ message }, ref) => {
        return (
            <div className="flex gap-12 rounded px-12 py-8" ref={ref}>
                <AvatarById
                    name={message.createdBy.displayName}
                    size="medium"
                    imageId={message.createdBy.pictureId}
                />
                <div className="w-full overflow-hidden">
                    <div className="flex justify-between">
                        <span className="font-medium">
                            {message.createdBy.displayName}
                        </span>
                        <RelativeTimeStamp timestamp={message.timestamp} />
                    </div>
                    <div>
                        <InlineMarkdown>{message.content}</InlineMarkdown>
                    </div>
                </div>
            </div>
        );
    },
);

export function ThreadView({
    thread,
    onBack,
    onClose,
    messages,
    mutateMessages,
}: {
    onClose: () => void;
    thread: ThreadDTO;
    onBack: () => void;
    messages: MessageDTO[];
    mutateMessages: KeyedMutator<ListResponse<MessageDTO>>;
}) {
    //focus element on mount
    const focusElementRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        focusElementRef.current?.focus();
    }, []);

    const { postMessage } = usePostMessageApi();
    const loggedInEmployee = useLoggedInEmployee();

    const lastReadMessageId = thread.unreadCount.readCursor ?? 0;
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView();
        }
    }, [messages]);

    const { target } = useIntersectionObserver(async (entries) => {
        const newestMessageId = messages[messages.length - 1].id;
        const shouldUpdateReadPointer = lastReadMessageId !== newestMessageId;

        if (entries && entries[0].isIntersecting && shouldUpdateReadPointer) {
            await putReadPointer(messages[messages.length - 1].id, thread.id);
        }
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const content = formData.get('content');

        if (!isDefined(content) || typeof content !== 'string') {
            return;
        }

        event.currentTarget.reset();
        await mutateMessages(
            async (currentData) => {
                if (!currentData) {
                    return currentData;
                }
                const message = await postMessage({
                    threadId: thread.id,
                    content,
                });
                return {
                    ...currentData,
                    values: [...currentData.values, message.value],
                };
            },
            {
                optimisticData: (currentData) => {
                    if (!currentData) {
                        return currentData as unknown as ListResponse<MessageDTO>;
                    }
                    return {
                        ...currentData,
                        values: [
                            ...currentData.values,
                            {
                                id: -1,
                                content,
                                thread: {
                                    id: thread.id,
                                    url: thread.url,
                                    unreadCount: thread.unreadCount,
                                    displayMessage: thread.displayMessage,
                                    participants: thread.participants,
                                    createdBy: thread.createdBy,
                                },
                                createdBy: loggedInEmployee!,
                                timestamp: new Date().toISOString(),
                            },
                        ],
                    };
                },
            },
        );
    };

    return (
        <>
            <ChatLayoutHeader>
                <span className="inline-flex items-center gap-12">
                    <Button
                        variant="icon"
                        aria-label="back"
                        data-testid="back"
                        onClick={onBack}
                    >
                        <ArrowBackIcon />
                    </Button>
                    <StackedAvatarContainer
                        maxAvatars={3}
                        size="medium"
                        avatarData={thread.participants?.map((participant) => ({
                            name: participant.displayName,
                            imageId: participant.pictureId,
                        }))}
                    />
                    <span
                        className={classNames('flex-initial', {
                            'font-medium': thread.unreadCount.count > 0,
                        })}
                    >
                        {thread.participants
                            ?.map((participant) => participant.displayName)
                            .join(', ')}
                    </span>
                </span>
                <Button
                    variant="icon"
                    aria-label="close"
                    data-testid="close-button"
                    onClick={onClose}
                >
                    <CloseIcon />
                </Button>
            </ChatLayoutHeader>
            <ChatLayoutContent>
                {messages.map((message, index, array) => (
                    <React.Fragment key={message.id}>
                        <ThreadMessage
                            message={message}
                            key={message.id}
                            ref={
                                index === array.length - 1 ? target : undefined
                            }
                        />
                        {message.id === lastReadMessageId &&
                            index !== array.length - 1 && (
                                <NewMessagesDivider />
                            )}
                    </React.Fragment>
                ))}
                <div ref={scrollRef} />
            </ChatLayoutContent>
            <ChatLayoutFooter>
                <form onSubmit={handleSubmit}>
                    <SendMessageInput
                        sender={loggedInEmployee}
                        data-testid="send-message-input"
                        name="content"
                        ref={focusElementRef}
                    />
                </form>
            </ChatLayoutFooter>
        </>
    );
}

export function NewMessagesDivider() {
    return (
        <div>
            <div className="border-b-grey-40 mx-24 border-b py-8">
                <FormattedMessage id="text_new" />
            </div>
            <div
            // style={{
            //     borderWidth: '1px',
            // }}
            >
                old
            </div>
        </div>
    );
}

export const SendMessageInput = React.forwardRef<
    HTMLInputElement,
    {
        sender?: EmployeeDTO;
    } & Omit<InputProps, 'type'>
>(({ sender, ...props }, ref) => (
    <div className="flex items-center gap-8 pb-12">
        {sender && (
            <AvatarById
                name={sender.displayName}
                size="medium"
                imageId={sender.pictureId}
            />
        )}
        <TextField
            name={props.name}
            data-testid={props['data-testid']}
            className="w-full"
            isRequired
            ref={ref}
        />
        <Button
            type="submit"
            variant="tertiary"
            data-testid="submit-new-message-button"
        >
            <SendIcon />
        </Button>
    </div>
));
