import {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
    MutableRefObject,
    useRef,
    useLayoutEffect,
} from 'react';

const EventSourceContext = createContext<EventSource | undefined | null>(null);
EventSourceContext.displayName = 'EventSourceContext';

export function EventSourceProvider({
    sseUrl,
    children,
}: {
    sseUrl: string;
    children: ReactNode;
}) {
    const [eventSource, setEventSource] = useState<EventSource | undefined>();

    useEffect(() => {
        if (sseUrl !== '') {
            const newEventSource = new EventSource(sseUrl, {
                withCredentials: true, // Send CSRF and JSESSIONID cookies
            });

            setEventSource(newEventSource);
            return () => {
                newEventSource.close();
            };
        }
        return;
    }, [sseUrl]);

    return (
        <EventSourceContext.Provider value={eventSource}>
            {children}
        </EventSourceContext.Provider>
    );
}

function useEventSourceContext() {
    const context = useContext(EventSourceContext);
    if (context === null) {
        throw new Error(
            'useEventSourceContext must be used within a EventSourceProvider',
        );
    }
    return context;
}

export function useEventSource<T = unknown>(
    channel: string,
    callback: (data: T) => void,
) {
    const eventSource = useEventSourceContext();
    const callbackRef = useLatest(callback);

    useEffect(() => {
        const eventHandler = (event: MessageEvent) => {
            try {
                const data = JSON.parse(event.data) as T;
                callbackRef.current(data);
            } catch (error) {
                console.error('Failed to parse event data', error);
            }
        };

        if (eventSource) {
            eventSource.addEventListener(channel, eventHandler);
        }

        return () => {
            if (eventSource) {
                eventSource.removeEventListener(channel, eventHandler);
            }
        };
    }, [eventSource, callbackRef, channel]);
}

function useLatest<T>(value: T): MutableRefObject<T> {
    const ref = useRef<T>(value);
    useLayoutEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
}
