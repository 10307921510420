import { getContextId } from '@tlx/astro-shared';
import { isDefined } from '../../utils/isDefined';

export function getUrlWithContext(
    url: string,
    params?: { [name: string]: string },
) {
    const urlWithParams = new URL(url, window.origin);

    const contextId = getContextId();

    urlWithParams.searchParams.set(
        'contextId',
        isDefined(contextId) ? contextId : '',
    );
    if (params !== undefined) {
        Object.entries(params).forEach(([key, value]) =>
            urlWithParams.searchParams.set(key, value),
        );
    }
    return urlWithParams.toString();
}
