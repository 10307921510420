export type ThreadDTO = {
    id: number;
    url: string;
    unreadCount: UnreadCountDTO;
    displayMessage: MessageDTO;
    participants: EmployeeDTO[];
    createdBy: EmployeeDTO;
};

export const EMPLOYEE_FIELDS = 'id,pictureId,displayName';
export const DISPLAY_MESSAGE_FIELDS = `*,createdBy(${EMPLOYEE_FIELDS})`;
export const MESSAGE_FIELDS = `*,createdBy(${EMPLOYEE_FIELDS})`;
export const THREAD_FIELDS = `*,displayMessage(${MESSAGE_FIELDS}),participants(${EMPLOYEE_FIELDS})`;

export type ChatEvent<T> = {
    type: 'NEW_MESSAGE' | 'NEW_THREAD';
    result: T;
};

export type EmployeeDTO = {
    id: number;
    displayName: string;
    pictureId: number;
};

export type MessageDTO = {
    id: number;
    content: string;
    createdBy: EmployeeDTO;
    timestamp: string;
    thread: Pick<ThreadDTO, 'id' | 'url'>;
};

export type UnreadCountDTO = {
    count: number;
    readCursor: number | null;
};
