import { useEffect, useState } from 'react';
import { useDeviceType } from '../../hooks/useDeviceType';
import { ActivityResult } from './activity/ActivityResult';
import { ActivityDTO } from './activity/types';
import { useActivitySearch } from './activity/useActivitySearch';
import { CompanyResult } from './company/CompanyResult';
import { CompanyDTO } from './company/types';
import { useCompanySearch } from './company/useCompanySearch';
import { ContactResult } from './contact/ContactResult';
import { ContactDTO } from './contact/types';
import { useContactSearch } from './contact/useContactSearch';
import { CustomerResult } from './customer/CustomerResult';
import { CustomerDTO } from './customer/types';
import { useCustomerSearch } from './customer/useCustomerSearch';
import { EmployeeResult } from './employee/EmployeeResult';
import { EmployeeDTO } from './employee/types';
import { useEmployeeSearch } from './employee/useEmployeeSearch';
import { InvoiceResult } from './invoice/InvoiceResult';
import { InvoiceDTO } from './invoice/types';
import { useInvoiceSearch } from './invoice/useInvoiceSearch';
import { MenuResult } from './menu/MenuResult';
import { MenuItemDTO } from './menu/types';
import { useMenuSearch } from './menu/useMenuSearch';
import { OrderResult } from './order/OrderResult';
import { OrderDTO } from './order/types';
import { useOrderSearch } from './order/useOrderResult';
import { OrderOfferResult } from './orderOffer/OrderOfferResult';
import { OrderOfferDTO } from './orderOffer/types';
import { useOrderOfferSearch } from './orderOffer/useOrderOfferSearch';
import { ProductResult } from './product/ProductResult';
import { ProductDTO } from './product/types';
import { useProductSearch } from './product/useProductSearch';
import { ProjectResult } from './project/ProjectResult';
import { ProjectDTO } from './project/types';
import { useProjectSearch } from './project/useProjectSearch';
import { PurchaseOrderResult } from './purchaseOrder/PurchaseOrderResult';
import { PurchaseOrderDTO } from './purchaseOrder/types';
import { usePurchaseOrderSearch } from './purchaseOrder/usePurchaseOrderSearch';
import {
    SearchDesktopContainer,
    SearchMobileContainer,
} from './SearchContainer';
import { SupplierResult } from './supplier/SupplierResult';
import { SupplierDTO } from './supplier/types';
import { useSupplierSearch } from './supplier/useSupplierSearch';
import { TravelExpenseResult } from './travelExpense/TravelExpenseResult';
import { TravelExpenseDTO } from './travelExpense/types';
import { useTravelExpenseSearch } from './travelExpense/useTravelExpenseSearch';
import { UseSearchCategoryReturn } from './types';
import { useDebouncedState } from './useDebouncedState';
import { useSearchLoadMoreContext } from './useSearchLoadMoreContext';
import { VoucherDTO } from './voucher/types';
import { useVoucherSearch } from './voucher/useVoucherSearch';
import { VoucherResult } from './voucher/VoucherResult';
import { ZendeskHelpResultDTO } from './zendeskHelp/types';
import { useZendeskHelpSearch } from './zendeskHelp/useZendeskHelpSearch';
import { ZendeskHelpResult } from './zendeskHelp/ZendeskHelpResult';

export function Search() {
    const [query, setQuery] = useDebouncedState('', 1000);
    const [isOpen, setIsOpen] = useState(false);
    
    const results: SearchResultsCollection  = {
        activityResults: useActivitySearch(query),
        companyResults: useCompanySearch(query),
        contactResults: useContactSearch(query),
        customerResults: useCustomerSearch(query),
        employeeResults: useEmployeeSearch(query),
        invoiceResults: useInvoiceSearch(query),
        menuResults: useMenuSearch(query),
        orderOfferResults: useOrderOfferSearch(query),
        orderResults: useOrderSearch(query),
        productResults: useProductSearch(query),
        projectResults: useProjectSearch(query),
        purchaseOrderResults: usePurchaseOrderSearch(query),
        supplierResults: useSupplierSearch(query),
        travelExpenseResults: useTravelExpenseSearch(query),
        voucherResults: useVoucherSearch(query),
        zendeskHelpResults: useZendeskHelpSearch(query),
    };
    const deviceType = useDeviceType();
    const SearchContainer =
        deviceType === 'mobile'
            ? SearchMobileContainer
            : SearchDesktopContainer;

    return (
        <SearchContainer
            defaultQuery={query}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            search={setQuery}
        >
            {isOpen && <SearchResults query={query} results={results} />}
        </SearchContainer>
    );
}

function SearchResults({ query, results }: { query: string, results: SearchResultsCollection }) {
    const { setLoadMoreCategory } = useSearchLoadMoreContext();

    useEffect(() => {
        setLoadMoreCategory(undefined);
    }, [setLoadMoreCategory, query]);

    return <SearchResultsView {...results} />;
}

interface SearchResultsCollection {
    activityResults: UseSearchCategoryReturn<ActivityDTO>;
    companyResults: UseSearchCategoryReturn<CompanyDTO>;
    contactResults: UseSearchCategoryReturn<ContactDTO>;
    customerResults: UseSearchCategoryReturn<CustomerDTO>;
    employeeResults: UseSearchCategoryReturn<EmployeeDTO>;
    invoiceResults: UseSearchCategoryReturn<InvoiceDTO>;
    menuResults: UseSearchCategoryReturn<MenuItemDTO>;
    orderOfferResults: UseSearchCategoryReturn<OrderOfferDTO>;
    orderResults: UseSearchCategoryReturn<OrderDTO>;
    productResults: UseSearchCategoryReturn<ProductDTO>;
    projectResults: UseSearchCategoryReturn<ProjectDTO>;
    purchaseOrderResults: UseSearchCategoryReturn<PurchaseOrderDTO>;
    supplierResults: UseSearchCategoryReturn<SupplierDTO>;
    travelExpenseResults: UseSearchCategoryReturn<TravelExpenseDTO>;
    voucherResults: UseSearchCategoryReturn<VoucherDTO>;
    zendeskHelpResults: UseSearchCategoryReturn<ZendeskHelpResultDTO>;
}

export function SearchResultsView(props: SearchResultsCollection) {
    return (
        <>
            <MenuResult {...props.menuResults} />
            <CustomerResult {...props.customerResults} />
            <SupplierResult {...props.supplierResults} />
            <ProjectResult {...props.projectResults} />
            <VoucherResult {...props.voucherResults} />
            <EmployeeResult {...props.employeeResults} />
            <InvoiceResult {...props.invoiceResults} />
            <OrderResult {...props.orderResults} />
            <OrderOfferResult {...props.orderOfferResults} />
            <ProductResult {...props.productResults} />
            <PurchaseOrderResult {...props.purchaseOrderResults} />
            <ActivityResult {...props.activityResults} />
            <ContactResult {...props.contactResults} />
            <CompanyResult {...props.companyResults} />
            <TravelExpenseResult {...props.travelExpenseResults} />
            <ZendeskHelpResult {...props.zendeskHelpResults} />
        </>
    );
}
