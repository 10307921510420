import { UseSearchCategoryReturn } from '../types';
import { orderCategory, OrderDTO } from './types';

import { useIntl } from 'react-intl';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';

export function OrderResult(props: UseSearchCategoryReturn<OrderDTO>) {
    const translation = useResultTitleTranslation('text_orders');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={orderCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/createOrderInvoices')}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: OrderDTO) => (
                    <OrderResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        category={orderCategory}
                        onLoadMore={loadMore}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function OrderResultItem({ data }: { data: OrderDTO }) {
    const url = getUrlWithContext('/execute/projectMenu', {
        projectId: data.id.toString(),
    });
    const { formatMessage } = useIntl();
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-order-icon">
                content_copy
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={orderCategory}>
                <SearchResultItemTitle>
                    {`${formatMessage({ id: 'text_order_number' })}: ${
                        data.number
                    }`}
                </SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_customer'}>
                    {data.customer?.name}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_our_contact'}>
                    {`${data.contact?.firstName ?? ''} ${
                        data.contact?.lastName ?? ''
                    }`}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_order_date'}>
                    {data.orderDate}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
