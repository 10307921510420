import {
    AsyncDropdownOptions,
    createAPIRequest,
    createAPIResponse,
    ResponseWrapper,
} from '@tlx/astro-shared';
import { ChatThreadDTO } from './types';
import {
    ArrowBackIcon,
    Button,
    CloseIcon,
    DropdownDrawer,
    DropdownField,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    SendIcon,
    TextField,
    Option,
    Input,
    Form,
} from '@tlx/atlas';
import { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { APIError } from '@tlx/astro-shared';

export function NewThread({
    onClose,
    setSelectedThread,
}: {
    onClose: () => void;
    setSelectedThread: (threadId: number | null) => void;
}) {
    const [newMessage, setNewMessage] = useState('');
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('0');
    const [selectedEmployeeError, setSelectedEmployeeError] = useState<
        string | undefined
    >();

    const [includeUrl, setIncludeUrl] = useState(true);
    const urlLink = window.location.href.toString();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const body: ChatThreadDTO = {
            messages: [
                {
                    content: newMessage,
                },
            ],
            participants: [
                {
                    id: parseInt(selectedEmployeeId),
                },
            ],
        };
        if (includeUrl) {
            body.threadUrl = urlLink;
        }

        const request = createAPIRequest('/v2/internal/chat/thread', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        try {
            const result = await fetch(request);
            const { value: thread } = await createAPIResponse<
                ResponseWrapper<ChatThreadDTO>
            >(request, result);

            setSelectedThread(thread.id ?? null);
        } catch (error) {
            if (error instanceof APIError) {
                const employeeIdErrors =
                    error.details.validationMessages?.filter(
                        (message) => message.field == 'participants',
                    );
                setSelectedEmployeeError(employeeIdErrors?.[0]?.message);
            }
        }
    };

    return (
        <div className="flex h-full flex-col">
            <ThreadHeader
                onClose={onClose}
                setSelectedThread={setSelectedThread}
            />
            <Form
                onSubmit={handleSubmit}
                className="flex h-full flex-col justify-between p-12"
                validationErrors={{
                    employeeId: selectedEmployeeError || '',
                }}
            >
                <EmployeeDropdown
                    selectedEmployeeId={selectedEmployeeId}
                    setSelectedEmployeeId={setSelectedEmployeeId}
                />
                <div className="flex flex-col gap-12">
                    <Input
                        data-testid="url-toggle"
                        type="toggle"
                        checked={includeUrl}
                        onChange={() => setIncludeUrl((prev) => !prev)}
                    />
                    Include URL in message
                    <UrlToLink url={urlLink} />
                </div>
                <div className="flex gap-8">
                    <TextField
                        name="content"
                        data-testid="new-message-input"
                        className="w-full"
                        isRequired
                        value={newMessage}
                        onChange={setNewMessage}
                    />
                    <Button
                        type="submit"
                        variant="icon"
                        aria-label="Send melding"
                        data-testid="submit-new-message-button"
                        className="text-blue-100"
                    >
                        <SendIcon />
                    </Button>
                </div>
            </Form>
        </div>
    );
}

function ThreadHeader({
    onClose,
    setSelectedThread,
}: {
    onClose: () => void;
    setSelectedThread: (threadId: number | null) => void;
}) {
    return (
        <div className="box-border flex w-full items-center justify-between p-12">
            <div className="flex items-center gap-12">
                <Button
                    data-testid="back-button"
                    variant="icon"
                    aria-label="tilbake"
                    onClick={() => setSelectedThread(null)}
                >
                    <ArrowBackIcon />
                </Button>
                <div className="text-xl">New message</div>
            </div>
            <Button
                data-testid="close-button"
                variant="icon"
                aria-label="lukk"
                onClick={onClose}
            >
                <CloseIcon />
            </Button>
        </div>
    );
}

function EmployeeDropdown({
    selectedEmployeeId,
    setSelectedEmployeeId,
}: {
    selectedEmployeeId: string;
    setSelectedEmployeeId: (id: string) => void;
}) {
    const { formatMessage } = useIntl();

    return (
        <DropdownField
            data-testid="employee-dropdown"
            selectedKey={selectedEmployeeId.toString()}
            name="employeeId"
            onSelectionChange={(value) =>
                setSelectedEmployeeId(value.toString())
            }
            label={formatMessage({ id: 'text_to' })}
            defaultSelectedKey="0"
            className="w-full"
            isRequired
        >
            <DropdownOpener />
            <DropdownDrawer>
                <DropdownSearch />
                <DropdownScrollContainer>
                    <AsyncDropdownOptions url="/v2/employee/searchForEmployees">
                        <Option value="0">
                            {formatMessage({
                                id: 'text_option_select_recipient',
                            })}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownScrollContainer>
            </DropdownDrawer>
        </DropdownField>
    );
}

function UrlToLink({ url }: { url: string }) {
    return <div>{url}</div>;
}
