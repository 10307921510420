import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import { buildStructEvent, PayloadBuilder } from '@snowplow/tracker-core';
import { getKeyboardEventKeybinding } from '../../utils/getKeyboardEventKeybinding';
import { isDefined } from '../../utils/isDefined';
import { getEventTarget } from '../utils';

const HOTKEYS: Record<string, string | undefined> = {
    c: 'open-company-chooser',
    s: 'toggle-sidebar',
    f: 'open-search',
};

export function HotkeysPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleKeyDown: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromKeyDownEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('keydown', handleKeyDown);
        },
    };
}

export function getPayloadBuilderFromKeyDownEvent(
    event: Event,
): PayloadBuilder | void {
    if (!(event instanceof KeyboardEvent)) {
        return;
    }

    // Ignore editable elements
    if (isEditableElement(getEventTarget(event))) {
        return;
    }

    const action = HOTKEYS[getKeyboardEventKeybinding(event)];

    if (isDefined(action) && action !== '') {
        return buildStructEvent({
            category: 'hotkey',
            action,
        });
    }
}

function isEditableElement(element: Element | null): boolean {
    if (!(element instanceof HTMLElement)) {
        return false;
    }

    return (
        element instanceof HTMLInputElement ||
        element instanceof HTMLTextAreaElement ||
        element.isContentEditable ||
        element.getAttribute('role') === 'listbox'
    );
}
