import { useLayout } from './useLayout';
import {
    Layout,
    LayoutActionBar,
    LayoutCallouts,
    LayoutContent,
    LayoutGlobalMessages,
    LayoutMain,
    LayoutScrollContainer,
    LayoutSidebar,
    LayoutTopbar,
} from './Layout';
import {
    DuplicateTabButton,
    TopbarBurgerButton,
    TopbarButtons,
    TopbarCompanyChooser,
    TopbarContainer,
    TopbarLogo,
    TopBarProfile,
    TopbarSearch,
} from '../Topbar/Topbar';
import { Search } from '../Search/Search';
import { HelpCenter } from '../HelpCenter/HelpCenter';
import { FavoriteButton } from '../Topbar/FavoriteButton';
import { TopbarNotifications } from '../TopbarNotifications/TopbarNotifications';
import { TopbarActionLog } from '../TopbarActionLog/TopbarActionLog';
import { TopbarErrorMessages } from '../TopbarErrorMessages/TopbarErrorMessages';
import { ChatMenu2 } from '../Chat2/ChatMenu2';
import { Banner } from '../Banner/Banner';
import { SidebarContainer, SidebarMenu } from '../Sidebar/Sidebar';
import { UnsupportedBrowserWarning } from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { Callouts } from '../Callouts/Callouts';
import { TermsOfService } from '../TermsOfService/TermsOfService';
import { KeyboardShortcutsDialog } from '../KeyboardShortcutsDialog/KeyboardShortcutsDialog';
import { SessionExpiredDialog } from '../SessionExpiredDialog/SessionExpiredDialog';
import { MaintenanceOverlay } from '../MaintenanceOverlay/MaintenanceOverlay';
import { ChatElement } from '../Chat/ChatElement';
import { IntlProvider } from 'react-intl';

// @ts-expect-error styles is a string
import styles from '../../index.css?inline';

export function LayoutContainer({ children }: { children?: React.ReactNode }) {
    const props = useLayout();

    if (!props.ready) {
        return null;
    }

    return (
        <IntlProvider locale={props.locale} messages={props.messages}>
            <style dangerouslySetInnerHTML={{ __html: styles }} />
            <Layout {...props}>
                <LayoutTopbar>
                    <TopbarContainer>
                        <TopbarBurgerButton
                            expanded={props.sidebarExpanded}
                            onClick={props.handleHamburgerButtonClick}
                        />
                        <TopbarLogo
                            href={props.sidebar.menu.data?.defaultContentUrl}
                        />
                        <TopbarCompanyChooser {...props.companyChooser} />
                        <TopbarSearch>
                            <Search />
                        </TopbarSearch>
                        <TopbarButtons>
                            <HelpCenter {...props.helpCenter} />
                            <FavoriteButton {...props.favorites} />
                            <slot name="export-button" />
                            <TopbarNotifications
                                {...props.topbarNotifications}
                            />
                            <TopbarActionLog {...props.topbarActionLog} />
                            <TopbarErrorMessages
                                {...props.topbarErrorMessages}
                            />
                            <DuplicateTabButton />
                            {props.showChat && (
                                <>
                                    <ChatElement />
                                    <ChatMenu2 />
                                </>
                            )}
                            <TopBarProfile {...props.profile} />
                        </TopbarButtons>
                    </TopbarContainer>
                </LayoutTopbar>
                <LayoutGlobalMessages>
                    {props.banner.banner && (
                        <Banner
                            banner={props.banner.banner}
                            onCancelBanner={props.banner.onCancelBanner}
                        />
                    )}
                </LayoutGlobalMessages>
                <LayoutMain>
                    <LayoutSidebar>
                        <SidebarContainer>
                            <SidebarMenu {...props.sidebar} />
                        </SidebarContainer>
                    </LayoutSidebar>
                    <LayoutScrollContainer>
                        <UnsupportedBrowserWarning />
                        {props.callouts.callouts.length > 0 && (
                            <LayoutCallouts>
                                <Callouts {...props.callouts} />
                            </LayoutCallouts>
                        )}
                        <LayoutContent>
                            <slot name="content" />
                            {children}
                        </LayoutContent>
                    </LayoutScrollContainer>
                </LayoutMain>
                <LayoutActionBar>
                    <slot name="actionbar" />
                </LayoutActionBar>
            </Layout>
            <TermsOfService />
            <KeyboardShortcutsDialog />
            <SessionExpiredDialog
                isLoggedOut={props.session.isLoggedOut}
                loginUrl={props.session.loginUrl}
            />
            <MaintenanceOverlay
                isSuspended={props.session.isSuspended}
                loginUrl={props.session.loginUrl}
            />
        </IntlProvider>
    );
}
