import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { useIntl } from 'react-intl';
import { CSSProperties, useState } from 'react';
import { offset, shift, useFloating } from '@floating-ui/react';
import { ChatIcon, Portal } from '@tlx/atlas';
import { Chat } from './Chat';
import useSWR from 'swr';
import { fetcher, ListResponse } from '@tlx/astro-shared';
import { ReactNode } from 'react';
import { ChatThreadDTO } from './types';

export function ChatMenu2() {
    const { formatMessage } = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const floating = useFloating({
        placement: 'bottom-start',
        // The 11px value is need to align the popup with the other popup on Topbar
        middleware: [shift({ padding: 11 }), offset(11)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const isProduction = window.location.hostname.includes('tripletex');

    if (isProduction) {
        return <div className="production h-[34px] w-0"></div>;
    }

    return (
        <ErrorBoundary>
            <button
                data-testid="chat-menu-button"
                className="tlx-topbar__button flex items-center"
                type="button"
                aria-label={formatMessage({ id: 'text_chat' })}
                ref={floating.refs.setReference}
                onClick={() => setIsOpen(!isOpen)}
            >
                <ChatBubble />
            </button>
            <Portal>
                {isOpen && (
                    <div
                        ref={floating.refs.setFloating}
                        style={popupPositioningStyle}
                        data-testId="chat-menu-popup"
                        className="border-grey-40 flex h-[520px] w-[400px] flex-col rounded border bg-white"
                    >
                        <Chat onClose={() => setIsOpen(false)} />
                    </div>
                )}
            </Portal>
        </ErrorBoundary>
    );
}

function ChatBubble() {
    const unreadCount = useFetchUnreadCount();

    return (
        <NumberBubble count={unreadCount}>
            <ChatIcon />
        </NumberBubble>
    );
}

export function useFetchUnreadCount(): number {
    const { data } = useSWR<ListResponse<ChatThreadDTO>>(
        '/v2/internal/chat/thread',
        fetcher,
    );

    const unreadCount = data?.values.filter(
        (thread) => thread.isRead === false,
    ).length;

    return unreadCount ?? 0;
}

export function NumberBubble({
    count,
    children,
}: {
    count: number;
    children?: ReactNode;
}) {
    return (
        <div className="relative grid">
            {children}
            <div className="atl-comment-bubble">
                {count === 1 && (
                    <div className="atl-comment__button-one-comment"></div>
                )}
                {count > 1 && count < 10 && (
                    <div className="atl-comment__button-count atl-comment-font-12">
                        {count < 10 ? count : '9+'}
                    </div>
                )}
                {count > 9 && (
                    <div className="atl-comment__button-count atl-comment-font-12 px-2">
                        {count < 10 ? count : '9+'}
                    </div>
                )}
            </div>
        </div>
    );
}
