import { SupportedLocale, getLocale } from '@tlx/intl';
import { useEffect, useReducer } from 'react';
import {
    UseIntlMessagesReturn,
    useIntlMessages,
} from '../../hooks/useIntlMessages';
import { useKeyboardShortcut } from '../../hooks/useKeyboardShortcut';
import { UseSessionReturn, useSession } from '../../hooks/useSession';
import { useVersionCheck } from '../../hooks/useVersionCheck';
import { keyboardCommands } from '../../utils/keyboardCommands';
import { matchNarrowScreen } from '../../utils/matchNarrowScreen';
import { UseBannerReturn, useBanner } from '../Banner/useBanner';
import { UseCalloutsReturn, useCallouts } from '../Callouts/useCallouts';
import {
    UseCompanyChooserReturn,
    useCompanyChooser,
} from '../CompanyChooser/useCompanyChooser';
import { UseFavoritesReturn, useFavorites } from '../Favorites/useFavorites';
import {
    UseHelpCenterReturn,
    useHelpCenter,
} from '../HelpCenter/useHelpCenter';
import { UseProfileReturn, useProfile } from '../Profile/useProfile';
import { SidebarProps, useSidebar } from '../Sidebar/Sidebar';
import { TopbarActionLogProps } from '../TopbarActionLog/TopbarActionLog';
import { useTopbarActionLog } from '../TopbarActionLog/useTopbarActionLog';
import { TopbarErrorMessagesProps } from '../TopbarErrorMessages/TopbarErrorMessages';
import { useTopbarErrorMessages } from '../TopbarErrorMessages/useTopbarErrorMessages';
import { TopbarNotificationsProps } from '../TopbarNotifications/TopbarNotifications';
import { useTopbarNotifications } from '../TopbarNotifications/useTopbarNotifications';
import { useDelayedStart } from './useDelayedStart';
import { useShowChat } from '../Chat/useShowChat';

export type UseLayoutReturn = {
    locale: SupportedLocale;
    messages: UseIntlMessagesReturn;
    companyChooser: UseCompanyChooserReturn;
    profile: UseProfileReturn;
    favorites: UseFavoritesReturn;
    helpCenter: UseHelpCenterReturn;
    sidebar: SidebarProps;
    session: UseSessionReturn;
    topbarNotifications: TopbarNotificationsProps;
    topbarActionLog: TopbarActionLogProps;
    topbarErrorMessages: TopbarErrorMessagesProps;
    sidebarExpanded: boolean;
    banner: UseBannerReturn;
    callouts: UseCalloutsReturn;
    handleHamburgerButtonClick(): void;
    ready: boolean;
    showChat: boolean;
};

export type LayoutState = {
    sidebarExpanded: boolean;
    sidebarToggledByUser: boolean;
    isNarrowScreen: boolean;
};

export type LayoutAction =
    | {
          type: 'userToggle';
      }
    | {
          type: 'reset';
      }
    | {
          type: 'windowSize';
      };

export const initialState: LayoutState = {
    // This invocation of matchNarrowScreen smells a bit
    sidebarExpanded: !matchNarrowScreen(),
    sidebarToggledByUser: false,
    isNarrowScreen: matchNarrowScreen(),
};

function expandedReducer(state: LayoutState, action: LayoutAction): boolean {
    // User actions are always respected
    if (action.type === 'userToggle') {
        return !state.sidebarExpanded;
    }

    // When resetting, close sidebar on narrow screens
    if (action.type === 'reset' && state.isNarrowScreen) {
        return false;
    }

    // When resizing window, toggle sidebar unless user has explicitly toggled
    if (action.type === 'windowSize' && !state.sidebarToggledByUser) {
        return !state.sidebarExpanded;
    }

    return state.sidebarExpanded;
}

function toggledByUserReducer(
    state: LayoutState,
    action: LayoutAction,
): boolean {
    if (action.type === 'userToggle') {
        return true;
    }

    return state.sidebarToggledByUser;
}

function narrowScreenReducer(
    state: LayoutState,
    action: LayoutAction,
): boolean {
    if (action.type === 'windowSize') {
        return !state.isNarrowScreen;
    }

    return state.isNarrowScreen;
}

export function layoutReducer(
    state: LayoutState,
    action: LayoutAction,
): LayoutState {
    return {
        sidebarExpanded: expandedReducer(state, action),
        sidebarToggledByUser: toggledByUserReducer(state, action),
        isNarrowScreen: narrowScreenReducer(state, action),
    };
}

export function useLayout(): UseLayoutReturn {
    const [{ sidebarExpanded, isNarrowScreen }, dispatch] = useReducer(
        layoutReducer,
        initialState,
    );

    const locale = getLocale();
    const messages = useIntlMessages(locale);
    const companyChooser = useCompanyChooser();
    const profile = useProfile();
    const favorites = useFavorites(locale);
    const helpCenter = useHelpCenter();

    const sidebar = useSidebar(favorites);
    const session = useSession();
    const topbarNotifications = useTopbarNotifications();
    const topbarActionLog = useTopbarActionLog();
    const topbarErrorMessages = useTopbarErrorMessages();
    const setCompanyChooserOpen = companyChooser.setOpen;

    const showChat = useShowChat();

    const banner = useBanner();
    const callouts = useCallouts();

    function handleHamburgerButtonClick() {
        dispatch({
            type: 'userToggle',
        });
    }

    function handleResize() {
        const willBeNarrowScreen = matchNarrowScreen();

        if (willBeNarrowScreen !== isNarrowScreen) {
            dispatch({
                type: 'windowSize',
            });
        }
    }

    function openCompanyChooser() {
        setCompanyChooserOpen(true);
    }

    function toggleSidebar() {
        dispatch({
            type: 'userToggle',
        });
    }

    function resetSidebar() {
        dispatch({
            type: 'reset',
        });
    }

    useKeyboardShortcut(
        keyboardCommands.openCompanyChooser,
        openCompanyChooser,
    );

    useKeyboardShortcut(keyboardCommands.toggleSidebar, toggleSidebar);

    useResetSidebar(resetSidebar);
    useResizeWindow(handleResize);
    useVersionCheck();

    const delayedStart = useDelayedStart();
    const ready =
        (messages &&
            companyChooser.ready &&
            sidebar.ready &&
            banner.ready &&
            callouts.ready) ||
        delayedStart;

    return {
        locale,
        messages,
        companyChooser,
        profile,
        favorites,
        helpCenter,
        sidebar,
        session,
        topbarNotifications,
        topbarErrorMessages,
        topbarActionLog,
        sidebarExpanded,
        banner,
        callouts,
        handleHamburgerButtonClick,
        ready,
        showChat,
    };
}

function useResetSidebar(resetSidebar: () => void) {
    useEffect(() => {
        document.addEventListener('sidebar:reset', resetSidebar);
        window.addEventListener('tlx:navigate', resetSidebar);

        return () => {
            document.removeEventListener('sidebar:reset', resetSidebar);
            window.removeEventListener('tlx:navigate', resetSidebar);
        };
    }, [resetSidebar]);
}

function useResizeWindow(handler: () => void) {
    useEffect(() => {
        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, [handler]);
}
