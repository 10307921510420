import { UseSearchCategoryReturn } from '../types';

import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { projectCategory, ProjectDTO } from './types';

export function ProjectResult(props: UseSearchCategoryReturn<ProjectDTO>) {
    const translation = useResultTitleTranslation('text_projects');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={projectCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/listProjectsExt')}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: ProjectDTO) => (
                    <ProjectResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        category={projectCategory}
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function ProjectResultItem({ data }: { data: ProjectDTO }) {
    const url = getUrlWithContext('/execute/projectMenu', {
        projectId: data.id.toString(),
    });

    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-project-icon">
                business_center
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={projectCategory}>
                <SearchResultItemTitle>{data.name}</SearchResultItemTitle>
                <SearchResultItemField
                    translationKey={'text_project_number_short'}
                >
                    {data.number}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'heading_customer'}>
                    {data.customer?.name}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_project_manager'}>
                    {`${data.projectManager?.firstName ?? ''}  ${
                        data.projectManager?.lastName ?? ''
                    }`}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
