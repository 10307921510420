import { createAPIRequest, createAPIResponse } from '@tlx/astro-shared';

export async function putReadPointer(messageId: number, threadId: number) {
    const url = `/v2/internal/thread/:updateReadCursor?threadId=${threadId}&messageId=${messageId}`;
    const request = createAPIRequest(url, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
        },
    });
    const response = await fetch(request);
    return await createAPIResponse(request, response);
}
