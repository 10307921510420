import { ReactNode } from 'react';
import {
    EmptyState,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateIllustration,
    EmptyStateTitle,
    EmptyStateActions,
} from '@tlx/atlas';
import { ThreadListElementLoading } from './ThreadListElement';

export function RecentThreadsLoadingState() {
    return (
        <>
            {Array.from({ length: 7 }).map((_, index) => (
                <ThreadListElementLoading key={index} />
            ))}
        </>
    );
}

export function RecentThreadsEmptyState({
    illustration,
    title,
    description,
    actions,
}: {
    illustration: ReactNode;
    title: string;
    description: string;
    actions?: ReactNode;
}) {
    return (
        <EmptyState direction="column">
            <EmptyStateIllustration>{illustration}</EmptyStateIllustration>
            <EmptyStateContent direction="column">
                <EmptyStateTitle>{title}</EmptyStateTitle>
                <EmptyStateDescription>{description}</EmptyStateDescription>
                {actions && <EmptyStateActions>{actions}</EmptyStateActions>}
            </EmptyStateContent>
        </EmptyState>
    );
}
