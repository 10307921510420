import { offset, shift, useFloating } from '@floating-ui/react';
import { getContextId, getCSRFToken } from '@tlx/astro-shared';
import { Icon, Portal, TestableProps } from '@tlx/atlas';
import classNames from 'classnames';
import React, {
    CSSProperties,
    forwardRef,
    ReactNode,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useComposedRefs } from '../Topbar/useComposedRefs';
import { ProfileDTO } from './types';
import { ProfileLanguageItem } from './ChangeLanguage';

export type ProfileProps = {
    profile: ProfileDTO;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickLogout?: React.MouseEventHandler;
};

export function ProfileSkeleton() {
    return (
        <div className="tlx-topbar__button tlx-topbar__button--rounded tlx-topbar__button--skeleton">
            <div className="tlx-topbar__button__icon tlx-topbar__button__icon--rounded tlx-topbar__button__icon--skeleton" />
        </div>
    );
}

export function Profile({ open, setOpen, profile }: ProfileProps) {
    return (
        <ProfileContainer
            open={open}
            setOpen={setOpen}
            imageUrl={profile.profileImageUrl}
        >
            <ProfileMenu>
                <ProfileMenuSummary>
                    <ProfilePopupImage
                        profileImageUrl={profile.profileImageUrl}
                    />
                    <ProfileMenuTitle>{profile.displayName}</ProfileMenuTitle>
                </ProfileMenuSummary>
                <ProfileMenuSeparator />
                {profile.menuItems.map((menuItem) => (
                    <ProfileMenuLinkItem
                        key={menuItem.testId}
                        title={menuItem.title}
                        url={menuItem.url}
                        icon={menuItem.icon}
                        data-testid={menuItem.testId}
                        mobileOnly={menuItem.theme === 'hide-on-desktop'}
                    />
                ))}
                <ProfileLanguageItem />
                <ProfileLogoutItem site={profile.site} />
            </ProfileMenu>
        </ProfileContainer>
    );
}

export function ProfileContainer({
    imageUrl,
    open,
    setOpen,
    children,
}: {
    imageUrl: string | null;
    open: boolean;
    setOpen: (value: boolean) => void;
    children: ReactNode;
}) {
    const { formatMessage } = useIntl();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const floating = useFloating({
        placement: 'bottom-end',
        middleware: [shift({ padding: 8 }), offset(11)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const composedButtonRef = useComposedRefs(
        buttonRef,
        floating.refs.setReference,
    );
    const composedPopupRef = useComposedRefs(
        popupRef,
        floating.refs.setFloating,
    );

    useLayoutEffect(() => {
        if (open) {
            floating.update();
        }
    }, [
        open,
        // Recalculate when the profile data is changed (if lazing loading is used)
        children,
    ]);

    return (
        <>
            <ProfileButton
                ref={composedButtonRef}
                onKeyUp={(event) => {
                    // Focus first menu item
                    if ((event.key === 'Enter' || event.key === ' ') && open) {
                        const firstMenuItem =
                            popupRef.current?.querySelector<HTMLAnchorElement>(
                                '[role="button"]',
                            );

                        firstMenuItem?.focus();
                    }
                }}
                onBlur={(event) => {
                    if (!popupRef.current?.contains(event.relatedTarget)) {
                        setOpen(false);
                    }
                }}
                onClick={() => {
                    // Safari <=14 workaround: buttons are not focusable by default,
                    // so we force-focus this one when it's clicked.
                    // This is needed so that our blur handler triggers correctly later on.
                    // The if-guard may not be strictly necessary, but it prevents "double-focusing"
                    // in the browsers that already have correct behavior.
                    if (document.activeElement !== buttonRef.current) {
                        buttonRef.current?.focus();
                    }
                    setOpen(!open);
                }}
                aria-expanded={open}
                aria-label={formatMessage({
                    id: open ? 'text_close_profile' : 'text_open_profile',
                })}
            >
                <ProfileButtonImage profileImageUrl={imageUrl} />
            </ProfileButton>
            <ProfilePopup
                ref={composedPopupRef}
                style={popupPositioningStyle}
                hidden={!open}
                tabIndex={-1}
                onKeyDown={(event) => {
                    // Focus button after pressing escape key
                    if (event.key === 'Escape') {
                        buttonRef.current?.focus();
                    }
                }}
                onBlur={(event) => {
                    if (!popupRef.current?.contains(event.relatedTarget)) {
                        setOpen(false);
                    }
                }}
            >
                {children}
            </ProfilePopup>
        </>
    );
}

export function ProfileMenuSummary({
    children,
}: {
    children?: React.ReactNode;
}) {
    return <div className="atl-flex atl-p-8 atl-items-center">{children}</div>;
}

export function ProfileButtonImage({
    profileImageUrl,
}: {
    profileImageUrl: string | null;
}) {
    const hasImage = profileImageUrl !== null && profileImageUrl !== '';

    return (
        <>
            {hasImage ? (
                <img
                    alt="Profile image"
                    src={profileImageUrl}
                    className={
                        'tlx-topbar__button__icon tlx-topbar__button__icon--tall tlx-topbar__button__icon--rounded'
                    }
                />
            ) : (
                <Icon className={'tlx-topbar__button__icon'}>
                    account_circle
                </Icon>
            )}
        </>
    );
}

export function ProfilePopupImage({
    profileImageUrl,
}: {
    profileImageUrl: string | null;
}) {
    const hasImage = profileImageUrl !== null && profileImageUrl !== '';

    return (
        <>
            {hasImage ? (
                <img
                    alt="Profile image"
                    src={profileImageUrl}
                    className={'tlx-profile__menu__picture'}
                />
            ) : (
                <Icon className={'tlx-profile__menu__no-picture'}>
                    account_circle
                </Icon>
            )}
        </>
    );
}

export const ProfileButton = forwardRef<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(function ProfileButton({ children, ...props }, ref) {
    return (
        <button
            ref={ref}
            aria-haspopup="true"
            data-testid="profile-button"
            className="tlx-topbar__button tlx-topbar__button--rounded atl-ml-4"
            {...props}
        >
            {children}
        </button>
    );
});

export const ProfilePopup = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(function ProfilePopup(props, ref) {
    return (
        <Portal>
            <div
                ref={ref}
                className="tlx-profile__popup atl-bg-white atl-p-4 atl-rounded"
                data-testid="profile-popup"
                {...props}
            />
        </Portal>
    );
});

export function ProfileMenu({ children }: { children?: React.ReactNode }) {
    return <div>{children}</div>;
}

export function ProfileMenuTitle({ children }: { children?: React.ReactNode }) {
    return (
        <div className="atl-px-8 atl-text-base atl-font-medium atl-truncate">
            {children}
        </div>
    );
}

export function ProfileMenuLinkItem({
    title,
    url,
    icon,
    'data-testid': testId,
    mobileOnly,
}: {
    title: string;
    url: string;
    icon: string;
    mobileOnly?: boolean;
} & TestableProps) {
    return (
        <a
            className={classNames('tlx-profile__menu-item', {
                'tlx-profile__menu-item-mobile-only': mobileOnly,
            })}
            href={url}
            data-testid={testId}
            role="button"
        >
            <Icon className="atl-mr-8 atl-p-4">{icon}</Icon>
            {title}
        </a>
    );
}

export function ProfileLogoutItem({ site }: { site: string }) {
    const formRef = useRef<HTMLFormElement>(null);
    const params = new URLSearchParams({
        site,
        contextId: getContextId() ?? '',
    });
    const url = '/execute/logout?' + params;

    useEffect(() => {
        const handleLogoutConfirmed = () => {
            formRef.current?.submit();
        };
        // Keep compatibility with the old solution. This event is expected when the logout is possible
        window.addEventListener('tlx:logout-confirmed', handleLogoutConfirmed);
        return () => {
            window.removeEventListener(
                'tlx:logout-confirmed',
                handleLogoutConfirmed,
            );
        };
    }, []);

    return (
        <form
            ref={formRef}
            action={url}
            method="POST"
            onSubmit={(formSubmitEvent) => {
                const logoutEvent = new CustomEvent('tlx:logout', {
                    detail: { site },
                    cancelable: true,
                });
                window.dispatchEvent(logoutEvent);
                if (logoutEvent.defaultPrevented) {
                    formSubmitEvent.preventDefault();
                }
            }}
        >
            <input
                name="csrfToken"
                type="hidden"
                value={getCSRFToken() ?? ''}
            />
            <ProfileLogoutButton />
        </form>
    );
}

export function ProfileLogoutButton() {
    const { formatMessage } = useIntl();

    return (
        <button
            className="tlx-profile__menu-item"
            data-testid={'profile-logout-button'}
        >
            <Icon className="atl-rounded-full atl-p-2 atl-m-2 atl-mr-8 atl-bg-yellow-40">
                power_settings_new
            </Icon>
            {formatMessage({ id: 'text_log_off' })}
        </button>
    );
}

export function ProfileMenuSeparator() {
    return <hr className="tlx-profile__menu-separator atl-m-0" />;
}
