import { Tooltip, TooltipContent, TooltipTrigger } from '@tlx/atlas';
import { FormattedDate, IntlShape, useIntl } from 'react-intl';

export function RelativeTimeStamp({ timestamp }: { timestamp: string }) {
    const intl = useIntl();
    const relativeTimestamp = getTime(new Date(timestamp), intl);

    return (
        <div className="text-grey-80">
            <Tooltip>
                <TooltipTrigger>
                    <span>{relativeTimestamp}</span>
                </TooltipTrigger>
                <TooltipContent>
                    <FormattedDate
                        value={timestamp}
                        day="numeric"
                        month="short"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </TooltipContent>
            </Tooltip>
        </div>
    );
}

function getTime(date: Date, intl: IntlShape) {
    const isToday = date.toDateString() === new Date().toDateString();

    if (isToday) {
        return intl.formatTime(date, {
            hour: 'numeric',
            minute: 'numeric',
        });
    }

    const oneYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
    const isLessThanOneYear = date > oneYearAgo;

    if (isLessThanOneYear) {
        return intl.formatDate(date, {
            day: 'numeric',
            month: 'short',
        });
    }

    return intl.formatDate(date, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
}
