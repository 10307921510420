import useSWR from 'swr';
import {
    AvatarById,
    createAPIRequest,
    fetcher,
    InlineMarkdown,
    ResponseWrapper,
} from '@tlx/astro-shared';
import { ChatThreadDTO, MessageDTO, ParticipantDTO } from './types';
import { RelativeTimeStamp } from './RelativeTimeStamp/RelativeTimeStamp';
import {
    ArrowBackIcon,
    Button,
    CloseIcon,
    SendIcon,
    TextField,
} from '@tlx/atlas';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useLoggedInEmployee } from '../Chat/ChatContent/api/fetchers/useLoggedInEmployee';

export function Thread({
    id,
    onClose,
    setSelectedThread,
}: {
    id: number;
    onClose: () => void;
    setSelectedThread: (threadId: number | null) => void;
}) {
    const [newMessage, setNewMessage] = useState('');
    const { data, mutate } = useSWR<ResponseWrapper<ChatThreadDTO>>(
        `/v2/internal/chat/thread/${id}?fields=messages(*,employee(displayName,pictureId)),participants(displayName,pictureId),threadUrl`,
        fetcher,
    );
    const thread = data?.value;
    const messages = thread?.messages ?? [];

    useEffect(() => {
        const markAsRead = async () => {
            const request = createAPIRequest(
                `/v2/internal/chat/thread/${id}/:markAsRead`,
                {
                    method: 'PUT',
                },
            );
            await fetch(request);
        };
        markAsRead();
    }, []);

    if (!thread) {
        return null;
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const request = createAPIRequest('/v2/internal/chat/message', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                content: newMessage,
                thread: {
                    id,
                },
            }),
        });

        await fetch(request);
        setNewMessage('');
        await mutate();
    };

    return (
        <div className="flex h-full flex-col overflow-hidden">
            <ThreadHeader
                thread={thread}
                onClose={onClose}
                setSelectedThread={setSelectedThread}
            />
            <div className="ml-4 flex h-full flex-col justify-between overflow-hidden">
                <div className="flex w-full flex-col overflow-auto">
                    {messages.map((message, index) => (
                        <Message
                            key={message.id}
                            message={message}
                            autofocus={index === messages.length - 1}
                        />
                    ))}
                </div>
                <div>{thread.threadUrl}</div> {/* Make this more sexy */}
                <div>
                    <form className="flex gap-8 p-12" onSubmit={handleSubmit}>
                        <TextField
                            name="content"
                            data-testid="new-message-input"
                            className="w-full"
                            isRequired
                            value={newMessage}
                            onChange={setNewMessage}
                        />
                        <Button
                            type="submit"
                            variant="icon"
                            aria-label="Send melding"
                            data-testid="submit-new-message-button"
                            className="text-blue-100"
                        >
                            <SendIcon />
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

function ThreadHeader({
    thread,
    onClose,
    setSelectedThread,
}: {
    thread: ChatThreadDTO;
    onClose: () => void;
    setSelectedThread: (threadId: number | null) => void;
}) {
    const loggedInEmployeeId = useLoggedInEmployee()?.id ?? 0;
    const otherParticipant: ParticipantDTO | undefined =
        thread?.participants?.find(
            (participant) => participant.id !== loggedInEmployeeId,
        );

    return (
        <div className="box-border flex w-full items-center justify-between p-12">
            <div className="flex items-center gap-12">
                <Button
                    data-testid="back-button"
                    variant="icon"
                    aria-label="tilbake"
                    onClick={() => setSelectedThread(null)}
                >
                    <ArrowBackIcon />
                </Button>
                <AvatarById
                    name={otherParticipant?.displayName ?? ''}
                    size="medium"
                    imageId={otherParticipant?.pictureId}
                />
                <span className="font-medium">
                    {otherParticipant?.displayName}
                </span>
            </div>
            <Button
                data-testid="close-button"
                variant="icon"
                aria-label="lukk"
                onClick={onClose}
            >
                <CloseIcon />
            </Button>
        </div>
    );
}

function Message({
    message,
    autofocus,
}: {
    message: MessageDTO;
    autofocus?: boolean;
}) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current && autofocus) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [autofocus]);

    return (
        <div className="flex gap-12 rounded px-12 py-8" ref={ref}>
            <AvatarById
                name={message.employee?.displayName ?? ''}
                size="medium"
                imageId={message.employee?.pictureId}
            />
            <div className="w-full overflow-hidden">
                <div className="flex justify-between">
                    <span className="font-medium">
                        {message.employee?.displayName}
                    </span>
                    <RelativeTimeStamp timestamp={message.timestamp ?? ''} />
                </div>
                <InlineMarkdown>{message.content}</InlineMarkdown>
            </div>
        </div>
    );
}
