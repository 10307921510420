import {
    createAPIRequest,
    createAPIResponse,
    getCSRFToken,
} from '@tlx/astro-shared';
import { CloseIcon, Icon } from '@tlx/atlas';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const HIDE_CHANGE_LANGUAGE_KEY = 'hideChangeLanguage';

export function ProfileLanguageItem() {
    const isProduction = document.location.host.includes('tripletex.no');
    const [hideButton, setHideButton] = useState(
        localStorage.getItem(HIDE_CHANGE_LANGUAGE_KEY) === 'true',
    );

    if (isProduction || hideButton) {
        return null;
    }

    return (
        <form onSubmit={handleLanguageChange}>
            <input
                name="csrfToken"
                type="hidden"
                value={getCSRFToken() ?? ''}
            />
            <ProfileChangeLanguageButton
                onClose={() => {
                    setHideButton(true);
                    localStorage.setItem(HIDE_CHANGE_LANGUAGE_KEY, 'true');
                }}
            />
        </form>
    );
}

function ProfileChangeLanguageButton({ onClose }: { onClose: () => void }) {
    const { formatMessage, locale } = useIntl();

    const [imgError, setImgError] = useState(false);

    return (
        <button
            className="tlx-profile__menu-item atl-py-0"
            data-testid={'profile-logout-button'}
        >
            {imgError ? (
                <Icon className="atl-rounded-full atl-p-2 atl-m-2 atl-mr-8">
                    language
                </Icon>
            ) : (
                <img
                    src={`/resources/imgs/flags/${locale === 'en' ? 'gb' : 'no'}.png`}
                    className="tlx-profile__menu__picture atl-m-2 atl-mr-4"
                    onError={() => setImgError(true)}
                />
            )}
            {formatMessage({ id: 'spacesuit_text_change_language' })}
            <span
                className="atl-button atl-button--icon atl-ml-auto"
                data-testid="profile-language-close-button"
                onClick={onClose}
            >
                <CloseIcon />
            </span>
        </button>
    );
}

async function handleLanguageChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    await changeLanguagePreference();

    window.location.reload();
}

async function changeLanguagePreference() {
    const ChangeRequest = createAPIRequest(
        `/v2/employee/preferences/:changeLanguage`,
        {
            method: 'PUT',
        },
    );
    const ChangeResponse = await fetch(ChangeRequest);
    await createAPIResponse(ChangeRequest, ChangeResponse);
}
