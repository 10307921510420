import { AddIcon, Button, CloseIcon, Input } from '@tlx/atlas';
import { useEffect, useRef, useState } from 'react';
import {
    FetchThreadsResponse,
    useFetchThreads,
} from './api/fetchers/useFetchThreads';
import { ChatLayoutContent, ChatLayoutHeader } from './ChatLayout';
import { RecentThreadsContent } from './RecentThreadsContent';
import { FormattedMessage } from 'react-intl';

export function useRecentThreads() {
    const { query, setQuery, debouncedQuery } = useRecentThreadsWithoutData();
    const fetchThreadsResponse = useFetchThreads(debouncedQuery);

    return {
        query,
        setQuery,
        fetchThreadsResponse,
    };
}

export function useRecentThreadsWithoutData() {
    const [query, setQuery] = useState('');

    const debouncedQuery = query; // TODO: debounce query

    return {
        query,
        setQuery,
        debouncedQuery,
    };
}
export type RecentThreadsProps = {
    query: string;
    setQuery: (query: string) => void;
    fetchThreadsResponse: FetchThreadsResponse;
};

export function RecentThreadsView({
    onClose,
    onClickThread,
    onClickNewThread,
    fetchThreadsResponse,
    setQuery,
    query,
}: {
    onClose: () => void;
    onClickThread: (threadId: number) => void;
    onClickNewThread: () => void;
} & RecentThreadsProps) {
    //focus the search input when the component is mounted
    const searchInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        searchInputRef.current?.focus();
    }, []);

    return (
        <>
            <ChatLayoutHeader>
                <div></div>
                <Button
                    data-testid="close-button"
                    variant="icon"
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </Button>
            </ChatLayoutHeader>
            <div className="flex gap-12 p-12">
                <Input
                    className="w-full"
                    type="search"
                    placeholder="Finn en melding"
                    data-testid="search-threads-input"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    ref={searchInputRef}
                />
                <Button
                    data-testid="new-message-button"
                    variant="primary"
                    aria-label="New message"
                    onClick={onClickNewThread}
                    className="flex-none"
                >
                    <FormattedMessage id="text_new_message" />
                    <AddIcon />
                </Button>
            </div>
            <ChatLayoutContent>
                <RecentThreadsContent
                    response={fetchThreadsResponse}
                    query={query}
                    resetQuery={() => setQuery('')}
                    onClickThread={onClickThread}
                />
            </ChatLayoutContent>
        </>
    );
}
