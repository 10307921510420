import {
    autoUpdate,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
} from '@floating-ui/react';
import { Portal } from '@tlx/atlas';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useIntl } from 'react-intl';
import { useDeviceType } from '../../hooks/useDeviceType';
import { isDefined } from '../../utils/isDefined';
import {
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem,
} from '../../utils/storage';
import { resolveUrl } from '../../utils/url';
import { ActiveChatButton } from './ActiveChatButton';
import { UseHelpCenterReturn } from './useHelpCenter';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace -- We want type-safe custom elements
    namespace JSX {
        interface IntrinsicElements {
            'tripletex-help': {
                class?: string;
                'chat-available': boolean;
            };
        }
    }
}

export type HelpCenterProps = UseHelpCenterReturn;

export function HelpCenter({
    baseUrl,
    isChatAvailable,
    isChatOpen,
    isChatting,
    unreadCount,
    openChat,
}: HelpCenterProps) {
    const { formatMessage } = useIntl();
    const { buttonRef, buttonProps, popoverRef, popoverProps, isOpen } =
        usePopover();

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    // Load help center custom element when open
    useEffect(() => {
        const id = 'tripletex-help-script';

        if (!isDefined(baseUrl) || !isOpen || document.getElementById(id)) {
            return;
        }

        const script = document.createElement('script');
        script.id = id;
        script.src = resolveUrl(baseUrl, 'tripletex-help.js');
        script.type = 'module';
        script.onload = () => setIsScriptLoaded(true);
        document.head.appendChild(script);
    }, [baseUrl, isOpen]);

    return (
        <>
            <button
                type="button"
                ref={buttonRef}
                className="tlx-topbar__button tlx-help-center-button"
                title={formatMessage({ id: 'text_help_center_title' })}
                data-testid="help-button"
                {...buttonProps}
            >
                {formatMessage({ id: 'text_help_short' })}
            </button>

            <Portal type="help-center-popover-portal">
                {isScriptLoaded && isOpen && (
                    <div
                        ref={popoverRef}
                        data-testid="help-popover"
                        {...popoverProps}
                    >
                        <tripletex-help
                            class="tlx-help-center-content"
                            chat-available={isChatAvailable}
                        ></tripletex-help>
                    </div>
                )}
                <ActiveChatButton
                    unreadCount={unreadCount}
                    hidden={!isChatAvailable || isChatOpen || !isChatting}
                    openChat={openChat}
                />
            </Portal>
        </>
    );
}

function usePopover() {
    const [isOpen, setIsOpen] = useIsOpen('help-center-last-opened');

    // The 11px value is needed to align popup with other popups
    const floating = useFloating<HTMLButtonElement>({
        open: isOpen,
        onOpenChange: (open) => {
            flushSync(() => setIsOpen(open));

            if (!open) {
                floating.elements.domReference?.focus();
            }
        },
        placement: 'bottom-start',
        middleware: [offset(11), shift({ padding: 11 })],
        whileElementsMounted: autoUpdate,
    });
    const context = floating.context;
    const deviceType = useDeviceType();
    const isMobile = deviceType === 'mobile';
    const click = useClick(context);
    const dismiss = useDismiss(context, {
        outsidePress: false,
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
    ]);
    const buttonProps = getReferenceProps();
    const popoverProps = getFloatingProps({
        className: classNames('tlx-help-center-popover', {
            'tlx-help-center-popover--mobile': isMobile,
        }),
        style: isMobile ? undefined : context.floatingStyles,
    });

    // Open and close popover when tlx:help-center events are emitted
    useEffect(() => {
        const handleClose = () => context.onOpenChange(false);
        const handleOpen = () => context.onOpenChange(true);

        window.addEventListener('tlx:help-center:close', handleClose);
        window.addEventListener('tlx:help-center:open', handleOpen);

        return () => {
            window.removeEventListener('tlx:help-center:close', handleClose);
            window.removeEventListener('tlx:help-center:open', handleOpen);
        };
    }, [context]);

    return {
        buttonRef: floating.refs.setReference,
        buttonProps,
        popoverRef: floating.refs.setFloating,
        popoverProps,
        isOpen,
    };
}

function useIsOpen(
    key: string,
    expiration = 12 * 60 * 60 * 1000, // 12 hours in milliseconds
): [boolean, (isOpen: boolean) => void] {
    const [isOpen, setIsOpen] = useState(() => {
        const now = new Date();
        const lastOpenedAt = new Date(getLocalStorageItem<string>(key, '0'));

        return now.getTime() - lastOpenedAt.getTime() < expiration;
    });

    useEffect(() => {
        if (isOpen) {
            setLocalStorageItem<string>(key, new Date().toISOString());
        } else {
            removeLocalStorageItem(key);
        }
    }, [key, isOpen]);

    return [isOpen, setIsOpen];
}
