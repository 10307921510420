import { initializeTourMe } from '@tlx/tourme-core';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { getElement, redirector } from '../../hooks/useTourMe';
import { initializeTracker } from '../../snowplow/index';
import { createLink } from '../../utils/createLink';

// @ts-expect-error styles is a string
import styles from '../../index.css?inline';
import { LayoutContainer } from './LayoutContainer';

export class LayoutElement extends HTMLElement {
    intervalId: number | undefined;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        createLink(
            'https://cdn.tripletex.no/google-fonts/rubik-400-500-800.css',
        );
        createLink(
            'https://cdn.tripletex.no/google-fonts/material-icons-outlined.css',
        );
        initializeTourMe({ getElement, redirector });
        initializeTracker();
        setupPortalContainers();

        this.render();
    }

    render() {
        if (this.shadowRoot !== null) {
            const root = createRoot(this.shadowRoot);
            root.render(createElement(LayoutContainer));
        } else {
            console.error('this.shadowRoot is null');
            return null;
        }
    }
}

// Set up two wrapper divs: one for userland portals and one for Spacesuit portals.
// The spacesuit portals should always be on visually top of the userland portals.
function setupPortalContainers() {
    if (document.getElementById('portals') === null) {
        const style = document.createElement('style');
        style.textContent = styles;

        const defaultPortals = document.createElement('div');
        defaultPortals.style.position = 'absolute';
        defaultPortals.style.zIndex = '0';
        defaultPortals.id = 'default-portals';

        const spacesuitPortals = document.createElement('div');
        spacesuitPortals.style.position = 'absolute';
        spacesuitPortals.style.zIndex = '0';
        spacesuitPortals.id = 'spacesuit-portals';

        const shadow = spacesuitPortals.attachShadow({ mode: 'open' });
        shadow.appendChild(style);

        const portals = document.createElement('div');
        portals.id = 'portals';
        portals.appendChild(defaultPortals);
        portals.appendChild(spacesuitPortals);

        document.body.appendChild(portals);
    }
}
