import useSWR from 'swr';
import { fetcher, ListResponse } from '@tlx/astro-shared';
import { useMemo } from 'react';
import { ChatEvent, MESSAGE_FIELDS, MessageDTO } from '../../Chat.types';
import { useEventSource } from '../../EventSourceContext';

export function useFetchMessages(threadId: number) {
    const { data, error, isLoading, mutate } = useSWR<ListResponse<MessageDTO>>(
        getFetchThreadsUrl(threadId),
        fetcher,
    );

    useEventSource<ChatEvent<MessageDTO>>('tripletex_chat', async (data) => {
        if (data.type === 'NEW_MESSAGE' && data.result.thread.id === threadId) {
            await mutate((currentData) => {
                if (currentData) {
                    return {
                        ...currentData,
                        values: [...currentData.values, data.result],
                    };
                }
                return currentData;
            });
        }
    });

    return {
        messages: useMemo(
            () =>
                data?.values.toSorted((a, b) => {
                    if (a.timestamp === b.timestamp) {
                        return 0;
                    }
                    return a.timestamp > b.timestamp ? 1 : -1;
                }) ?? [],
            [data],
        ),
        error,
        isLoading,
        mutate,
    };
}

function getFetchThreadsUrl(threadId: number) {
    return `/v2/internal/thread/${threadId}/messages?fields=${MESSAGE_FIELDS}`;
}
