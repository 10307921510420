import useSWR from 'swr';
import { fetcher, ResponseWrapper } from '@tlx/astro-shared';
import { EMPLOYEE_FIELDS, EmployeeDTO } from '../../Chat.types';

export function useLoggedInEmployee() {
    const { data } = useSWR<ResponseWrapper<{ employee: EmployeeDTO }>>(
        getWhoAmIUrl({ fields: [`employee(${EMPLOYEE_FIELDS})`] }),
        fetcher,
    );

    return data?.value.employee;
}
function getWhoAmIUrl({ fields }: { fields: string[] }): string | null {
    const params = new URLSearchParams();
    params.set('fields', [...fields].sort().join(','));

    return `/v2/token/session/>whoAmI?${params}`;
}
