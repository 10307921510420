import useSWR from 'swr';
import { fetcher, ListResponse } from '@tlx/astro-shared';
import { KeyedMutator } from 'swr/_internal';
import { THREAD_FIELDS, ThreadDTO } from '../../Chat.types';

export interface FetchThreadsResponse {
    threads: ThreadDTO[];
    error: any;
    isLoading: boolean;
    mutate: KeyedMutator<ListResponse<ThreadDTO>>;
}

export function useFetchThreads(query: string): FetchThreadsResponse {
    const { data, error, isLoading, mutate } = useSWR<ListResponse<ThreadDTO>>(
        getFetchThreadsUrl(query),
        fetcher,
        {
            keepPreviousData: true,
        },
    );

    return {
        threads: data?.values || [],
        error,
        isLoading,
        mutate,
    };
}

function getFetchThreadsUrl(query: string) {
    return `/v2/internal/thread/employeeThreads?query=${query}&fields=${THREAD_FIELDS}`;
}
