import { ThreadsOverview } from './ThreadsOverview';
import { useState } from 'react';
import { Thread } from './Thread';
import { NewThread } from './NewThread';

export function Chat({ onClose }: { onClose: () => void }) {
    // TODO: Use MemoryRouter or refactor this
    const [selectedThread, setSelectedThread] = useState<number | null>(null);

    if (selectedThread === 0) {
        return (
            <NewThread
                onClose={onClose}
                setSelectedThread={setSelectedThread}
            />
        );
    }

    if (selectedThread !== null) {
        return (
            <Thread
                id={selectedThread}
                onClose={onClose}
                setSelectedThread={setSelectedThread}
            />
        );
    }

    return (
        <ThreadsOverview
            onClose={onClose}
            setSelectedThread={setSelectedThread}
        />
    );
}
