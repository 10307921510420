import { ThreadDTO } from './Chat.types';
import { useFetchMessages } from './api/fetchers/useFetchMessages';
import { ThreadView } from './ThreadView';

export function ConnectedThreadView({
    thread,
    onBack,
    onClose,
}: {
    onClose: () => void;
    thread: ThreadDTO;
    onBack: () => void;
}) {
    const { messages, mutate, isLoading } = useFetchMessages(thread.id);

    // Todo:Add some loading state
    if (messages.length === 0 || isLoading) {
        return null;
    }

    return (
        <ThreadView
            thread={thread}
            messages={messages}
            onBack={onBack}
            onClose={onClose}
            mutateMessages={mutate}
        />
    );
}
