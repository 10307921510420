import {
    Button,
    Modal,
    ModalContent,
    ModalFooter,
    ModalLayout,
    ModalTitle,
} from '@tlx/atlas';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { isDefined } from '../../utils/isDefined';

export function SessionExpiredDialog(props: {
    isLoggedOut: boolean;
    loginUrl?: string;
}) {
    const [isCancelled, setIsCancelled] = useState(false);
    const isOpen = props.isLoggedOut && !isCancelled;
    const { formatMessage } = useIntl();
    const handleCancelClick = () => setIsCancelled(true);
    const loginUrl = getLoginUrlWithRedirect(props.loginUrl);

    return (
        <Modal open={isOpen} className="tlx-session-expired-dialog">
            {isOpen && (
                <ModalLayout>
                    <ModalTitle>
                        {formatMessage({ id: 'text_warning' })}
                    </ModalTitle>
                    <ModalContent className="atl-text-base">
                        {formatMessage({ id: 'validation_not_logged_in' })}
                    </ModalContent>
                    <ModalFooter>
                        <div className="atl-flex atl-gap-8">
                            <a
                                className="atl-button atl-button--primary"
                                data-testid="session-expired-dialog-ok-button"
                                href={loginUrl}
                            >
                                OK
                            </a>
                            <Button
                                variant="secondary"
                                data-testid="session-expired-dialog-cancel-button"
                                onClick={handleCancelClick}
                            >
                                {formatMessage({ id: 'button_cancel' })}
                            </Button>
                        </div>
                    </ModalFooter>
                </ModalLayout>
            )}
        </Modal>
    );
}

function getLoginUrlWithRedirect(
    loginUrl: string | undefined,
): string | undefined {
    if (!isDefined(loginUrl)) {
        return;
    }

    const url = new URL(loginUrl, window.origin);
    const redirectPath =
        window.location.pathname +
        window.location.search +
        window.location.hash;

    url.searchParams.set('redirectPath', redirectPath);

    return url.toString();
}
