import { isDefined } from '../utils/isDefined';

export function getEventTarget(event: Event): Element | null {
    // We might be inside a Shadow DOM so we can't use event.target
    const target = event.composedPath()[0];

    if (!(target instanceof Element)) {
        return null;
    }

    return target;
}

export function getClasses(element: HTMLElement): string[] {
    return Array.from(element.classList);
}

export function getElementId(element: HTMLElement): string {
    if (
        isDefined(element.dataset.trackingid) &&
        element.dataset.trackingid !== ''
    ) {
        return element.dataset.trackingid;
    }
    if (isDefined(element.dataset.testid) && element.dataset.testid !== '') {
        return element.dataset.testid;
    }
    const name = element.getAttribute('name');
    if (isDefined(name) && name !== '') {
        return name;
    }
    if (isDefined(element.id) && element.id !== '') {
        return element.id;
    }
    if (isDefined(element.className) && element.className !== '') {
        return element.className;
    }
    return 'Unknown element id';
}

export function getFormId(element: HTMLElement): string {
    const form = element.closest('form');

    if (form !== null) {
        return (
            form.getAttribute('data-trackingid') ??
            form.getAttribute('id') ??
            ''
        );
    } else {
        // We have some fake forms in Tripletex such as popup dialogs that we'd like to track
        const formId = element
            .closest('[data-formid]')
            ?.getAttribute('data-formid');

        if (isDefined(formId)) {
            return formId;
        }
    }

    return '';
}
