import { useApiSearch } from '../useSearch';
import { CompanyDTO } from './types';

const endpoint = `/v2/company/query`;
const searchParams = new URLSearchParams({
    fields: 'id,name,organizationNumber',
});

export function useCompanySearch(query: string) {
    return useApiSearch<CompanyDTO>(endpoint, query, searchParams);
}
