import { isDefined } from '../../utils/isDefined';

export function getPageTitleWithNewAmount(
    oldPageTitle: string,
    unreadCount: number,
): string {
    const pageTitle = oldPageTitle.replace(/^\(\d+\) /, '');
    return unreadCount > 0 ? `(${unreadCount}) ${pageTitle}` : pageTitle;
}

export function getPageTitleWithNewTitle(
    oldPageTitle: string,
    newPageTitle: string,
): string {
    const unreadCount = oldPageTitle.match(/^\((\d+)\) /)?.[1];
    return isDefined(unreadCount) && unreadCount !== ''
        ? `(${unreadCount}) ${newPageTitle}`
        : newPageTitle;
}
