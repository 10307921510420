import { useEffect, useState } from 'react';
import {
    Button,
    GhostIllustration,
    InboxIllustration,
    SearchIllustration,
} from '@tlx/atlas';
import { FetchThreadsResponse } from './api/fetchers/useFetchThreads';
import {
    RecentThreadsEmptyState,
    RecentThreadsLoadingState,
} from './RecentThreadsEmptyState';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThreadListElement } from './ThreadListElement';

export function RecentThreadsContent({
    response: { error, threads, isLoading },
    query,
    resetQuery,
    onClickThread,
}: {
    response: FetchThreadsResponse;
    query: string;
    resetQuery: () => void;
    onClickThread: (threadId: number) => void;
}) {
    const { formatMessage } = useIntl();
    const debouncedLoading = useDebouncedLoading(isLoading, 500);

    if (error) {
        return (
            <RecentThreadsEmptyState
                illustration={<GhostIllustration />}
                title={formatMessage({ id: 'text_chat_error_title' })}
                description={formatMessage({
                    id: 'text_chat_error_description',
                })}
            />
        );
    }

    if (debouncedLoading) {
        return <RecentThreadsLoadingState />;
    }

    if (threads.length === 0 && query) {
        return (
            <RecentThreadsEmptyState
                illustration={<SearchIllustration />}
                title={formatMessage({ id: 'text_chat_search_failed_title' })}
                description={formatMessage({
                    id: 'text_chat_search_failed_description',
                })}
                actions={
                    <Button data-testid="reset-search" onClick={resetQuery}>
                        <FormattedMessage id="text_reset_search" />
                    </Button>
                }
            />
        );
    }

    if (threads.length === 0) {
        return (
            <RecentThreadsEmptyState
                illustration={<InboxIllustration />}
                title={formatMessage({ id: 'text_chat_empty_title' })}
                description={formatMessage({
                    id: 'text_chat_empty_description',
                })}
            />
        );
    }

    return (
        <>
            {threads.map((thread) => (
                <ThreadListElement
                    key={thread.id}
                    thread={thread}
                    onClick={() => onClickThread(thread.id)}
                />
            ))}
        </>
    );
}

function useDebouncedLoading(isLoading: boolean, delay: number) {
    const [debounced, setDebounced] = useState<boolean>(isLoading);

    useEffect(() => {
        if (!isLoading) {
            setDebounced(false);
            return;
        }
        const timeout = setTimeout(() => setDebounced(isLoading), delay);
        return () => clearTimeout(timeout);
    }, [delay, isLoading]);

    return debounced;
}
