import { useEffect, useRef } from 'react';

export function useIntersectionObserver(
    onIntersection: (
        entries?: IntersectionObserverEntry[],
        observer?: IntersectionObserver,
    ) => void,
    { root, rootMargin, threshold }: IntersectionObserverInit = {},
) {
    const targetElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const target = targetElement.current;

        if (target === null) {
            return;
        }

        const observer = new IntersectionObserver(onIntersection, {
            root: root ?? findClosestScrollContainer(target),
            rootMargin: rootMargin ?? '0px',
            threshold: threshold ?? 0.9,
        });

        observer.observe(target);

        return () => {
            observer.unobserve(target);
        };
    }, [onIntersection, root, rootMargin, threshold]);

    return {
        target: targetElement,
    };
}

function findClosestScrollContainer(element: HTMLElement): HTMLElement | null {
    let current: HTMLElement | null = element;
    while (current !== null && current !== document.body) {
        const overflowY = window.getComputedStyle(current).overflowY;
        if (overflowY === 'auto' || overflowY === 'scroll') {
            return current;
        }
        current = current.parentElement;
    }

    return null;
}
