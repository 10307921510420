import useSWR from 'swr';
import { fetcher, ResponseWrapper } from '@tlx/astro-shared';

type Features = {
    Chat: boolean;
};

export function useShowChat(): boolean {
    const { data } = useSWR<ResponseWrapper<Features>>(
        '/v2/pilotFeature/',
        fetcher,
    );

    return !!data?.value?.Chat;
}
