import { AddIcon, Button, CloseIcon } from '@tlx/atlas';
import useSWR from 'swr';
import {
    AvatarById,
    fetcher,
    InlineMarkdown,
    ListResponse,
} from '@tlx/astro-shared';
import { RelativeTimeStamp } from './RelativeTimeStamp/RelativeTimeStamp';
import { ChatThreadDTO, ParticipantDTO } from './types';
import { useLoggedInEmployee } from '../Chat/ChatContent/api/fetchers/useLoggedInEmployee';
import classNames from 'classnames';

export function ThreadsOverview({
    onClose,
    setSelectedThread,
}: {
    onClose: () => void;
    setSelectedThread: (threadId: number) => void;
}) {
    const fields =
        '*,createdByEmployee(id,displayName),messages(*,employee(*)),participants(*)';
    const { data } = useSWR<ListResponse<ChatThreadDTO>>(
        `/v2/internal/chat/thread?fields=${fields}`,
        fetcher,
    );

    const threads = data?.values.toReversed() ?? [];
    const loggedInEmployeeId = useLoggedInEmployee()?.id ?? 0;

    return (
        <div className="flex h-full flex-col">
            <div className="box-border flex w-full justify-end p-12">
                <Button
                    data-testid="new-thread-button"
                    variant="icon"
                    aria-label="lukk"
                    onClick={() => setSelectedThread(0)}
                >
                    <AddIcon />
                </Button>
                <Button
                    data-testid="close-button"
                    variant="icon"
                    aria-label="lukk"
                    onClick={onClose}
                >
                    <CloseIcon />
                </Button>
            </div>
            <div className="box-border flex h-full w-full flex-col gap-4 overflow-auto px-8">
                {threads.map((thread) => (
                    <ThreadSummary
                        key={thread.id}
                        thread={thread}
                        onSelectThread={(threadId) => {
                            setSelectedThread(threadId);
                        }}
                        loggedInEmployeeId={loggedInEmployeeId}
                    />
                ))}
            </div>
        </div>
    );
}

function ThreadSummary({
    thread,
    onSelectThread,
    loggedInEmployeeId,
}: {
    thread: ChatThreadDTO;
    onSelectThread: (threadId: number) => void;
    loggedInEmployeeId: number;
}) {
    const lastMessage = thread.messages?.at(-1);
    const otherParticipant: ParticipantDTO | undefined =
        thread.participants?.find(
            (participant) => participant.id !== loggedInEmployeeId,
        );

    return (
        <div
            className="hover:bg-blue-10 grid cursor-pointer grid-cols-[min-content,1fr] gap-8 rounded px-12 py-4"
            onClick={() => onSelectThread(thread.id ?? 0)}
        >
            <AvatarById
                name={otherParticipant?.displayName ?? ''}
                size="medium"
                imageId={otherParticipant?.pictureId}
            />
            <div className="overflow-hidden">
                <div className="flex justify-between">
                    <span className="font-medium">
                        {otherParticipant?.displayName}
                    </span>
                    <span className="text-grey-80">
                        <RelativeTimeStamp
                            timestamp={lastMessage?.timestamp ?? ''}
                        />
                    </span>
                </div>
                <div
                    className={classNames(
                        'overflow-ellipsis whitespace-nowrap',
                        {
                            'font-bold': !thread.isRead,
                        },
                    )}
                >
                    <InlineMarkdown>
                        {lastMessage?.content ?? ''}
                    </InlineMarkdown>
                </div>
            </div>
        </div>
    );
}
