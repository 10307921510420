import classNames from 'classnames';
import { HTMLProps, ReactNode, useLayoutEffect, useRef, useState } from 'react';

// Used for an accordion transition - expand/collapse vertically.
// This cannot easily be done using only CSS transitions, as the
// transition needs to know the height of the element before it
// starts.
type ExpandCollapseProps = {
    expanded?: boolean;
    animationDuration?: number;
    children?: ReactNode;
} & HTMLProps<HTMLDivElement>;
export function ExpandCollapse({
    children,
    expanded,
    className,
    style,
    animationDuration = 200,
    ...props
}: ExpandCollapseProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [delayedExpanded, setDelayedExpanded] = useState(expanded);

    const hasDoneFirstRender = useRef(false);

    useLayoutEffect(() => {
        let timeout: number;

        // The useLayoutEffect will run also on first render, but we don't want
        // to animate it then.
        if (!hasDoneFirstRender.current) {
            hasDoneFirstRender.current = true;
            return;
        }

        // This should never happen, but let's short-circuit if it does.
        if (ref.current === null) {
            return;
        }

        if (expanded) {
            // Show/expand the element super quickly, to measure its
            // height before the animation starts.
            ref.current.style.height = 'auto';
            ref.current.style.display = 'block';
            const height = ref.current.offsetHeight;
            ref.current.style.height = '0';

            requestAnimationFrame(() => {
                if (ref.current instanceof HTMLDivElement) {
                    ref.current.style.height = height + 'px';
                }
            });

            timeout = window.setTimeout(() => {
                // This should never happen, but let's short-circuit if it does.
                if (ref.current === null) {
                    return;
                }

                ref.current.style.height = '';
                ref.current.style.display = '';
                setDelayedExpanded(true);
            }, animationDuration);
        } else {
            // Hide/collapse the list
            ref.current.style.height = ref.current.offsetHeight + 'px';

            requestAnimationFrame(() => {
                if (ref.current instanceof HTMLDivElement) {
                    ref.current.style.height = '0';
                }
            });

            timeout = window.setTimeout(() => {
                // We need to check ref.current again - it could have
                // been unmounted during the setTimeout
                if (ref.current instanceof HTMLUListElement) {
                    ref.current.style.display = '';
                    ref.current.style.height = '';
                }

                setDelayedExpanded(false);
            }, animationDuration);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [expanded, animationDuration]);

    const isTransitioning = expanded !== delayedExpanded;
    const classNameComputed = classNames(
        className,
        isTransitioning ? 'tlx-expand-collapse--transitioning' : '',
        delayedExpanded
            ? 'tlx-expand-collapse--expanded'
            : 'tlx-expand-collapse--collapsed',
    );

    return (
        <div
            ref={ref}
            {...props}
            style={{
                ...style,
                // @ts-expect-error not added to CSSProperties (yet...)
                '--tlx-expand-collapse-transition-time':
                    animationDuration + 'ms',
            }}
            className={classNameComputed}
        >
            {children}
        </div>
    );
}
