import {
    ArrowBackIcon,
    Button,
    CloseIcon,
    DropdownDrawer,
    DropdownField,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Input,
    Label,
    Option,
} from '@tlx/atlas';
import React, { FormEventHandler, useState } from 'react';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { ThreadDTO } from './Chat.types';
import { usePostCreateThreadApi } from './api/actions/postCreateThreadApi';
import { useLoggedInEmployee } from './api/fetchers/useLoggedInEmployee';
import {
    ChatLayoutContent,
    ChatLayoutFooter,
    ChatLayoutHeader,
} from './ChatLayout';
import { SendMessageInput } from './ThreadView';
import { FormattedMessage, useIntl } from 'react-intl';

export function CreateNewThread({
    onClose,
    onBack,
    onCreateThread,
}: {
    onClose: () => void;
    onBack: () => void;
    onCreateThread: (thread: ThreadDTO) => void;
}) {
    const { formatMessage } = useIntl();
    //focus dropdown when the component is mounted
    const focusElementRef = React.useRef<HTMLSelectElement>(null);
    React.useEffect(() => {
        focusElementRef.current?.focus();
    }, []);

    const [selectedEmployeeId, setSelectedEmployeeId] = React.useState('');
    const { createNewThread } = usePostCreateThreadApi();
    const loggedInEmployee = useLoggedInEmployee();

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const formData = new FormData(event.currentTarget);
        const text = formData.get('message')?.toString() ?? '';
        const relativeUrl = formData.get('relativeUrl');
        const urlDisplayName = formData.get('urlDisplayName');
        console.log({ relativeUrl, urlDisplayName }); // nulls if not checked; otherwise strings.

        if (!selectedEmployeeId || !text) {
            return;
        }

        const response = await createNewThread({
            thread: {
                url: '',
                text,
                participants: [Number(selectedEmployeeId)],
            },
        });
        form.reset();
        onCreateThread(response.value);
    };

    return (
        <>
            <ChatLayoutHeader>
                <span className="inline-flex gap-12 items-center">
                    <Button
                        variant="icon"
                        aria-label="back"
                        data-testid="back"
                        onClick={onBack}
                    >
                        <ArrowBackIcon />
                    </Button>
                    <span className="font-medium">
                        <FormattedMessage id="text_new_message" />
                    </span>
                </span>
                <Button
                    data-testid="close-button"
                    variant="icon"
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </Button>
            </ChatLayoutHeader>
            <ChatLayoutContent>
                <DropdownField
                    data-testid="employee-dropdown"
                    selectedKey={selectedEmployeeId.toString()}
                    name="employeeId"
                    onSelectionChange={(value) =>
                        setSelectedEmployeeId(value.toString())
                    }
                    ref={focusElementRef}
                    label={formatMessage({ id: 'text_to' })}
                    defaultSelectedKey="0"
                >
                    <DropdownOpener />
                    <DropdownDrawer>
                        <DropdownSearch />
                        <DropdownScrollContainer>
                            <AsyncDropdownOptions url="/v2/employee/searchForEmployees">
                                <Option value="0">
                                    {formatMessage({
                                        id: 'option_select_recipient',
                                    })}
                                </Option>
                            </AsyncDropdownOptions>
                        </DropdownScrollContainer>
                    </DropdownDrawer>
                </DropdownField>
            </ChatLayoutContent>
            <ChatLayoutFooter>
                <form onSubmit={handleSubmit}>
                    <SendMessageInput
                        disabled={selectedEmployeeId === ''}
                        sender={loggedInEmployee}
                        data-testid="send-message-input"
                        name="message"
                    />
                    <NewThreadUrlToggle />
                </form>
            </ChatLayoutFooter>
        </>
    );
}

function NewThreadUrlToggle() {
    const currentUrl = usePollUrlChange();
    const title = usePollDocumentHeader();
    const [checked, setChecked] = useState(true);

    return (
        <div className="flex gap-12 items-center">
            <Input
                id="toggle-url"
                type="toggle"
                data-testid="toggle-url"
                value={currentUrl}
                name="relativeUrl"
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
            />
            <input
                type="hidden"
                name="urlDisplayName"
                value={title}
                disabled={!checked}
            />
            <Label htmlFor="toggle-url" className="text-blue-100">
                {title}
            </Label>
        </div>
    );
}

// Navigation api is not implemented yet for Firefox or Safari, so we have to poll for it, similar to astro.
// They have a polling interval of 100ms, so I'm using the same here. We could probably check if navigation exists
// and use that if it does, but this works fine as-is.
function usePollUrlChange() {
    const [currentUrl, setCurrentUrl] = React.useState(() =>
        getRelativeUrl(window.location.href),
    );

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentUrl((prev) => {
                const newRelativeUrl = getRelativeUrl(window.location.href);
                if (prev !== newRelativeUrl) {
                    return newRelativeUrl;
                }
                return prev;
            });
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return currentUrl;
}

function getRelativeUrl(urlString: string) {
    const url = new URL(urlString);
    url.searchParams.delete('contextId');
    const searchParams = url.searchParams.toString();
    return `${url.pathname}${searchParams === '' ? '' : '?' + searchParams}${
        url.hash
    }`;
}

function usePollDocumentHeader() {
    const [title, setTitle] = React.useState(() => getH1Title());

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTitle((prev) => {
                const newTitle = getH1Title();
                if (!newTitle) {
                    return prev;
                }
                return newTitle === prev ? prev : newTitle;
            });
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return title;
}

function getH1Title() {
    return document.querySelector('h1')?.textContent ?? 'Link til side';
}
