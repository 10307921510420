import {
    SkeletonAvatar,
    SkeletonText,
    StackedAvatarContainer,
} from '@tlx/atlas';
import { InlineMarkdown } from '@tlx/astro-shared';
import React, { ComponentProps } from 'react';
import classNames from 'classnames';
import { ThreadDTO } from './Chat.types';
import { NumberBubble } from './NumberBubble/NumberBubble';
import { RelativeTimeStamp } from './RelativeTimeStamp/RelativeTimeStamp';

type ThreadListElementProps = {
    thread: ThreadDTO;
    onClick: () => void;
};

export function ThreadListElementLoading() {
    return (
        <ThreadListElementLayout>
            <SkeletonAvatar />
            <ThreadListElementContent>
                <SkeletonText className="w-full" />
            </ThreadListElementContent>
        </ThreadListElementLayout>
    );
}

export function ThreadListElement({ thread, onClick }: ThreadListElementProps) {
    return (
        <ThreadListElementLayout
            onClick={onClick}
            className="hover:bg-blue-10 cursor-pointer"
        >
            <NumberBubble count={thread.unreadCount.count}>
                <div className="z-0">
                    <StackedAvatarContainer
                        maxAvatars={3}
                        size="medium"
                        avatarData={thread.participants.map((participant) => ({
                            name: participant.displayName,
                            imageId: participant.pictureId,
                        }))}
                    />
                </div>
            </NumberBubble>
            <ThreadListElementContent>
                <div className="flex justify-between">
                    <span
                        className={classNames('flex-initial', {
                            'font-medium': thread.unreadCount.count > 0,
                        })}
                    >
                        {thread.participants
                            .map((participant) => participant.displayName)
                            .join(', ')}
                    </span>
                    <span className="text-grey-80">
                        <RelativeTimeStamp
                            timestamp={thread.displayMessage.timestamp}
                        />
                    </span>
                </div>
                <div className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                    <InlineMarkdown>
                        {thread.displayMessage.content}
                    </InlineMarkdown>
                </div>
            </ThreadListElementContent>
        </ThreadListElementLayout>
    );
}

function ThreadListElementLayout({
    children,
    className,
    ...props
}: { children: React.ReactNode } & ComponentProps<'div'>) {
    return (
        <div
            className={classNames('flex gap-12 px-12 rounded py-8', className)}
            {...props}
        >
            {children}
        </div>
    );
}

function ThreadListElementContent({ children }: { children: React.ReactNode }) {
    return <div className="w-full overflow-hidden">{children}</div>;
}
