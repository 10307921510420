import React from 'react';

export function ChatLayoutHeader({ children }: { children: React.ReactNode }) {
    return (
        <div className="tlx-chat-header atl-p-12 atl-flex atl-justify-between border-b-grey-40">
            {children}
        </div>
    );
}

export function ChatLayoutContent({ children }: { children: React.ReactNode }) {
    return (
        <div className="tlx-chat-content atl-flex atl-flex-col atl-overflow-y-auto atl-h-full atl-p-12">
            {children}
        </div>
    );
}

export function ChatLayoutFooter({ children }: { children: React.ReactNode }) {
    return <div className="tlx-chat-footer atl-p-12">{children}</div>;
}
