import { useApiSearch } from '../useSearch';
import { ProjectDTO } from './types';
import { getContextId } from '@tlx/astro-shared';

const endpoint = `/v2/project/query`;
const searchParams = new URLSearchParams({
    fields: 'id,name,number,customer(name),projectManager(firstName,lastName)',
});

export function useProjectSearch(query: string) {
    const isTripletex = getContextId() === '3001';

    return useApiSearch<ProjectDTO>(
        endpoint,
        isTripletex ? '' : query,
        searchParams,
    );
}
