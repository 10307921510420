import { Icon, Portal } from '@tlx/atlas';
import { CSSProperties, useEffect, useState } from 'react';
import { useFloating } from '@floating-ui/react';
import { offset, shift } from '@floating-ui/react';
import {
    EventSourceProvider,
    useEventSource,
} from './ChatContent/EventSourceContext';
import { getContextId } from '@tlx/astro-shared';
import { Chat } from './ChatContent/Chat';
import { useIntl } from 'react-intl';
import { useFetchTotalCount } from './ChatContent/api/fetchers/useFetchTotalCount';
import { NumberBubble } from './ChatContent/NumberBubble/NumberBubble';

export function ChatMenu() {
    const { formatMessage } = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const floating = useFloating({
        placement: 'bottom-start',
        // The 11px value is need to align the popup with the other popup on Topbar
        middleware: [shift({ padding: 11 }), offset(11)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    // TODO: Use the correct URL
    const SSEUrl = `http://localhost:8092/subscribe?contextId=${getContextId()}`;

    return (
        <EventSourceProvider sseUrl={SSEUrl}>
            <button
                data-testid="chat-menu-button"
                className="tlx-topbar__button flex items-center"
                type="button"
                aria-label={formatMessage({ id: 'text_chat' })}
                ref={floating.refs.setReference}
                onClick={() => setIsOpen(!isOpen)}
            >
                <ChatBubble isOpen={isOpen} />
            </button>
            <Portal>
                {isOpen && (
                    <div
                        ref={floating.refs.setFloating}
                        style={popupPositioningStyle}
                        data-testId="chat-menu-popup"
                        className="tlx-chat-layout border-grey-40 flex h-[520px] w-[400px] flex-col rounded border bg-white"
                    >
                        <Chat onClose={() => setIsOpen(false)} />
                    </div>
                )}
            </Portal>
        </EventSourceProvider>
    );
}

function ChatBubble({ isOpen }: { isOpen: boolean }) {
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

    const chatCount = useFetchTotalCount() ?? 0;

    useEffect(() => {
        if (chatCount !== undefined) {
            setUnreadMessagesCount(chatCount);
        }
    }, [chatCount]);

    useEventSource('tripletex_chat', () => {
        if (!isOpen) {
            setUnreadMessagesCount((oldCount) => oldCount + 1);
        }
    });

    return (
        <NumberBubble count={unreadMessagesCount}>
            <Icon className="tlx-topbar__button__icon">chat</Icon>
        </NumberBubble>
    );
}
