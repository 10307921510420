import useSWR from 'swr';
import { fetcher, ResponseWrapper } from '@tlx/astro-shared';

export function useFetchTotalCount() {
    const { data } = useSWR<ResponseWrapper<number>>(
        '/v2/internal/thread/>totalUnreadCount',
        fetcher,
    );

    return data?.value;
}
